// ** React Imports
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Divider,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import {
  getuserBookingImages,
  updateEnquiryStatus,
} from "../BookingDashboard/apis/BookingDashboardApi";
import {
  addNewBookingNote,
  deleteTheBookingNotes,
  getAllBookingNotes,
} from "../CalendarPage/api/BookingAndCustomerNotesApi";
import { Link } from "react-router-dom";
import { getTimestamp, validateCheckin } from "../../Utils/Utils";
import CardforButtons from "./Views/CardforButtons";

function ReadMore({ children = 100 }) {
  const text = children;

  const [isShow, setIsShowLess] = useState(true);

  const result = isShow ? text.slice(0, 100) : text;

  function toggleIsShow() {
    setIsShowLess(!isShow);
  }

  return (
    <p>
      {result}
      <span className="btn btn-link p-0" onClick={toggleIsShow}>
        {isShow ? "Read More" : "Read Less"}
      </span>
    </p>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  displayHeading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  secondaryHeading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "1.1rem",
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1),
    wordBreak: "break-word",
  },
}));

const GroupCustomerInformation = (props) => {
  const {
    reservationData,
    eventData,
    reload,
    viewFolio,
    closeBackDrop,
    hotelType,
    resId,
    fetchReservationData,
    selectedReservationData,
    hotelId,
    hotelSlotType,
  } = props;
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  console.log("reservationData", reservationData, "eventData", eventData);

  const [currentEpoch, setcurrentEpoch] = useState(0);

  const currency = "INR";
  const classes = useStyles();
  const date2 = new Date();

  // fields for for offline cheque payment

  const [offlineCheckAmt, setOfflineCheckAmt] = useState(
    reservationData?.balance_due
  );

  // tag modal apis
  const [allHotelTags, setallHotelTags] = useState([]);
  const [allBookingTags, setallBookingTags] = useState([]);
  const [bookingRemark, setbookingRemark] = useState("");

  const [isIsha, setisIsha] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("HotelEmail") === "cottage.admin@ishafoundation.org"
    ) {
      setisIsha(true);
    } else {
      setisIsha(false);
    }
  }, [localStorage.getItem("HotelEmail")]);

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/booking-tags/get-all?bookingId=${reservationData?.booking_id}` +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
      .then((res) => {
        setallBookingTags(res[0]?.tags?.split(","));
        setbookingRemark(res[0]?.remark);
      })
      .catch((err) => console.error("Error in fetching tags"));

    handleSpringDataRequest(
      `core/api/v1/hotel-booking-tags/get-all` +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
      .then((res) => {
        const data = res.filter((item) => item.status === "ACTIVE");
        setallHotelTags(data);
      })
      .catch((err) => console.error("Error in fetching tags"));
  }, []);

  // Validate if the checkin must be allowed
  const [checkinHours, setCheckinHours] = useState(9);
  const handleCheckinHours = () =>
    handleSpringDataRequest(
      "core/api/v1/pc/get-checkin-hours" +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
      .then((res) =>
        setCheckinHours(res ? Number(res.hours_before_checkin) : checkinHours)
      )
      .catch((err) => toast.error("Error in fetching the checkin hours"));

  useEffect(() => {
    handleCheckinHours();
  }, []);

  const hours_start =
    getTimestamp(
      moment(eventData.event.extendedProps.actual_start).format(
        "YYYY-MM-DDTHH:mm:ss"
      )
    ) -
    Number(checkinHours) * 3600000;
  const hours_end = getTimestamp(
    moment(eventData.event.extendedProps.actual_end).format(
      "YYYY-MM-DDTHH:mm:ss"
    )
  );

  useEffect(() => {
    handleSpringDataRequest(
      "web-api/get-today-hotel-epoch" +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
      .then((res) => setcurrentEpoch(res))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row" className={classes.textField}>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Booking Id{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {" "}
            {reservationData.booking_id}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>Room type </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.room_type_name}
          </Typography>
        </Grid>

        <Grid
          container
          whiteSpace={"nowrap"}
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
        >
          <Typography className={classes.displayHeading}>Check-In </Typography>
          <Typography className={classes.secondaryHeading}>
            {" "}
            {moment(
              reservationData.booking_checkin.replace("T", " "),
              "YYYY-MM-DD HH:mm:ss"
            ).format("MMMM Do YYYY, h A")}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>Check-out </Typography>
          <Typography className={classes.secondaryHeading}>
            {moment(
              reservationData.booking_checkout.replace("T", " "),
              "YYYY-MM-DD HH:mm:ss"
            ).format("MMM Do YYYY, h A")}
          </Typography>
        </Grid>
        {/* <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className={classes.displayHeading}>
            Booking made on
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {moment(
              reservationData.booking_made_on,
              "YYYY-MM-DD HH:mm:ss"
            ).format("MMM Do YYYY, h:m A")}
          </Typography>
        </Grid> */}
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Booking made on
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {moment(
              reservationData.booking_made_on,
              "YYYY-MM-DD HH:mm:ss"
            ).format("MMM Do YYYY, h:m A")}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>Rate plan </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.rate_plan_name}
          </Typography>
        </Grid>
        {/* <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>Room type </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.room_type_name}
          </Typography>
        </Grid> */}
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Adults / Children / Infant
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.adults} / {reservationData.children} /{" "}
            {reservationData.children1}
          </Typography>
        </Grid>
        {/* <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>Children</Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.children}
          </Typography>
        </Grid> */}
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Booked Room No.(s)
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.reservation_rooms}
          </Typography>
        </Grid>
        {/* <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Payment made{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(reservationData.payment_made).toFixed(2)}`}
          </Typography>
        </Grid> */}
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          style={{ marginTop: "1rem" }}
        >
          <Typography className={classes.displayHeading}>
            Room Booking Amount
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(
              reservationData.total_pretax_amount_with_services
            ).toFixed(2)}`}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          style={{ marginTop: "1rem" }}
        >
          <Typography className={classes.displayHeading}>
            Room Booking Tax{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(
              reservationData.total_room_tax_amount
            ).toFixed(2)}`}
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Addons Amount{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(
              reservationData.total_services_pretax_amount
            ).toFixed(2)}`}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Addons Tax{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(reservationData.total_services_tax).toFixed(
              2
            )}`}
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Final amount with tax{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(
              reservationData.total_amount_with_services
            ).toFixed(2)}`}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>Total Tax </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(reservationData.total_tax_amount).toFixed(
              2
            )}`}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          style={{ marginTop: "1rem" }}
        >
          <Typography className={classes.displayHeading}>
            Payment made{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(reservationData.payment_made).toFixed(2)}`}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          style={{ marginTop: "1rem" }}
        >
          <Typography className={classes.displayHeading}>
            Balance Due{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${currency} ${Number(reservationData.balance_due).toFixed(2)}`}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography className={classes.displayHeading}>
            Access code{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {reservationData.seam_access_code === ""
              ? "NA"
              : reservationData.seam_access_code}
          </Typography>
        </Grid>
        {/* {reservationData?.ota_booking_id !== null && (
          <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography className={classes.displayHeading}>
              OTA Booking Id
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {reservationData?.ota_booking_id === undefined
                ? "NA"
                : reservationData?.ota_booking_id}
            </Typography>
          </Grid>
        )} */}

        {isIsha && (
          <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography className={classes.displayHeading}>Tags</Typography>
            <Typography className={classes.secondaryHeading}>
              {allBookingTags?.toString()}
            </Typography>
          </Grid>
        )}
        {isIsha && (
          <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography className={classes.displayHeading}>Remark</Typography>
            <Typography className={classes.secondaryHeading}>
              {bookingRemark}
            </Typography>
          </Grid>
        )}

        {reservationData?.special_requests !== "" && (
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className={classes.displayHeading}>
              Special request
            </Typography>
            <Typography className={classes.secondaryHeading}>
              <ReadMore> {reservationData?.special_requests} </ReadMore>
            </Typography>
          </Grid>
        )}
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className={classes.displayHeading}>
            {reservationData.seam_access_code === "" ? "" : ""}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className={classes.displayHeading}>
            {reservationData.seam_access_code === null ? "" : ""}
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid item>
        <Link
          to={{
            pathname: `/folio/${reservationData.booking_id}`,
            search: `&hotelId=${reservationData.booking_id.split("_")[1]}`,
          }}
          target="_blank"
        >
          <CardforButtons
            heading={"View folio"}
            variant="custom-button"
          ></CardforButtons>
        </Link>
      </Grid> */}
    </div>
  );
};

export default GroupCustomerInformation;
