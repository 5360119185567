import React, { useEffect, useState } from "react";
import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api/index";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListenerHelper from "../../Utils/ClickAwayListenerHelper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import moment from "moment";
import Currencies from "../../Utils/Currencies.json";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { connect, shallowEqual, useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Button,
  Tooltip,
  Typography,
  Divider,
  Chip,
  IconButton,
  Grid,
} from "@mui/material";
import CounterPaymentsLogTable from "./ViewManagePayments/CounterPaymentsLogTable";
import CashDepositsTable from "./ViewManagePayments/CashDepositsTable";
import PaymentGatewayLogTable from "./ViewManagePayments/PaymentGatewayLogTable";
import MockLoaderTable from "./ViewManagePayments/MockLoaderTable";
import AddCard from "./CardManage/AddCard";
import {
  ChargeAuthCardOTA,
  ChargeAuthCardSaved,
} from "./CardManage/ChargeAuthCard";
import { epochTime } from "../../Utils/EpochTime";
import BillToCTATable from "./ViewManagePayments/BillToCTATable";
import {
  ENV,
  stripePublicKey,
} from "../CalendarPage/constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import { getUserWizardRender } from "../AccessControl/apis/AccessControlApi";
import * as MdIcons from "react-icons/md";
import axios from "axios";
import * as IoIcons from "react-icons/io5";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import IshaGpms from "./ViewManagePayments/IshaGpms";
import { magicLinkBreakdownMap } from "../CalendarPage/constants/Calendar-constants";
import MultiPaymentModeSelect from "./MultiPaymentModeSelect";
import { unionTerritories } from "../../Utils/UnionTerritories";
import { useTranslation } from "react-i18next";
import i18n from "../../modules/Auth/pages/i18n";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  maxHeight: 550,
  pb: 2,
  overflowY: "scroll",
};

function InvoiceSummary({
  onClick,
  bookingId,
  data,
  folioSummary,
  updateInvoice,
  currency,
  loggedUserEmail,
  summaryData,
  updateSummaryData,
  updateSummaryDataFromCache,
  otaBookingInfoData,
  customPgData,
  updateHotelPgData,
  handleTrueWrapperMockLoader,
  handleFalseWrapperMockLoader,
  handleTrueSummaryMockLoader,
  handleFalseSummaryMockLoader,
  hotelLogo,
  fetchBookingData,
  isGrid,
  convertedAmount,
  hotelConvertedCurrency,
  hotelConvertedCurrencySymbol,
  hotelCurrency,
  hotelType,
  roomIdMapping,
  checked,
  userEmail,
  igst,
  dbigst,
  utgst,
  isNightAuditOn,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  //Amplitude check on Invoice Summary page
  const amplitudeEventProperties = {
    hotel_id: data?.hotelId,
    environment: ENV,
    email: data?.accessControl?.email,
    role: data?.accessControl?.ADMINRIGHTS,
  };
  const [subUser, setsubUser] = useState(data?.accessControl?.ADMINRIGHTS);
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const [paymentViewAccess, setpaymentViewAccess] = useState("NO");
  const [isAdmin, setisAdmin] = useState("");
  const [cardHolder, setcardHolder] = useState(
    folioSummary?.invoice?.customer_name
  );
  const [approvalCode, setapprovalCode] = useState("");
  const [terminalId, setterminalId] = useState("");

  const getAccessControlData = async () => {
    handleSpringDataRequest(
      `core/api/v1/accesscontrol/addUserWizardRender?email=${loggedUserEmail}`
    )
      .then((res) => {
        setisAdmin(res.ADMINRIGHTS);
        setpaymentViewAccess(
          JSON.parse(res?.user_feature_map?.PaymentViewAccess).read === "YES"
            ? "YES"
            : "NO"
        );
      })
      .catch((err) => {
        console.error(err);
      });
    // const accessData = await getUserWizardRender(hotelId, loggedUserEmail);
    // setpaymentViewAccess(
    //   accessData.accessUser.accessmap.user_access_map.payment_view_access
    // );
  };
  useEffect(() => {
    getAccessControlData();
  }, []);

  //Early checkout data
  const [showEarlyCheckout, setShowEarlyCheckout] = useState(false);
  const openEarlyCheckoutModal = () => setShowEarlyCheckout(true);
  const closeEarlyCheckoutModal = () => setShowEarlyCheckout(false);
  const [newCheckoutDate, setNewCheckoutDate] = useState(new Date());
  const [earlyCheckoutLoader, setEarlyCheckoutLoader] = useState(false);

  const [currentEpoch, setcurrentEpoch] = useState(0);

  useEffect(() => {
    setCheckinLoading(true);
    setCheckoutLoading(true);
    handleSpringDataRequest("web-api/get-today-hotel-epoch")
      .then((res) => {
        setcurrentEpoch(res);
        setCheckinLoading(false);
        setCheckoutLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCheckoutLoading(false);
        setCheckinLoading(false);
      });
  }, []);

  //Setting resId for any mapping
  const [selectedResId, setSelectedResId] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const handleRoomIdChange = (roomId) => {
    setSelectedRoomId(roomId);
    roomIdMapping.map((resData, index) => {
      if (roomId == resData?.roomId) {
        setSelectedResId(resData?.resId);
      }
    });
  };
  const [previewIsha, setPreviewIsha] = useState(
    sessionStorage.getItem("previewImageIsha")
  );
  const [ereceiptgenerated, setEreceiptgenerated] = useState(
    sessionStorage.getItem("EreciptGenerated")
  );
  useEffect(() => {
    setPreviewIsha(sessionStorage.getItem("previewImageIsha"));
    setEreceiptgenerated(sessionStorage.getItem("EreciptGenerated"));
  }, [
    sessionStorage.getItem("previewImageIsha"),
    sessionStorage.getItem("EreciptGenerated"),
  ]);

  useEffect(() => {
    if (roomIdMapping?.length > 0) {
      // setSelectedRoomId(roomIdMapping[0]?.roomId);
      // setSelectedResId(roomIdMapping[0]?.resId);
    }
  }, [roomIdMapping]);

  // Getting the user type
  const userType = data?.accessControl?.ADMINRIGHTS;

  // Fetching before checkin hours property allows to checkin
  const [checkinHours, setCheckinHours] = useState(9);
  const handleCheckinHours = () =>
    handleSpringDataRequest("core/api/v1/pc/get-checkin-hours")
      .then((res) => setCheckinHours(Number(res.hours_before_checkin)))
      .catch((err) => toast.error("Error in fetching the checkin hours"));
  useEffect(() => {
    handleCheckinHours();
  }, [bookingId]);

  // const todayDate = new Date();
  // const formatCheckinDate = new Date(summaryData?.booking_checkin);
  // const formatCheckoutDate = new Date(summaryData?.booking_checkout);
  // const todayDate = Number(epochTime(new Date()));
  const todayDate = moment().format("YYYY-MM-DDTHH:mm:ss");
  const handleDateFormatter = (dateReceived) => {
    const [dateComponents, timeComponents] = dateReceived?.split("T");
    const [year, month, day] = dateComponents?.split("-");
    const hours =
      timeComponents?.split(":")[0] === undefined
        ? 0
        : timeComponents?.split(":")[0];
    const minutes =
      timeComponents?.split(":")[1] === undefined
        ? 0
        : timeComponents?.split(":")[1];
    const seconds =
      timeComponents?.split(":")[2] === undefined
        ? 0
        : timeComponents?.split(":")[2];
    const newDate = new Date(
      +year,
      month - 1,
      +day,
      +hours,
      +minutes,
      +seconds
    );
    const timeStamp = newDate.getTime();
    return Number(timeStamp);
  };

  const hours_start =
    handleDateFormatter(folioSummary?.invoice?.checkin) -
    Number(checkinHours) * 3600000;
  const hours_end = handleDateFormatter(folioSummary?.invoice?.checkout);

  const validateCheckin = () => {
    if (handleDateFormatter(todayDate) >= hours_start) {
      return true;
    } else {
      return false;
    }
  };

  const enablePerformEMVTxn = ["FORTIS", "TSYS", "SHIFT4", "STRIPE"].includes(
    customPgData?.active_pg_name
  );

  const enableAddCard = ["FORTIS", "TSYS", "SHIFT4", "STRIPE"].includes(
    customPgData?.active_pg_name
  );

  // const enableRefundCard = ["FORTIS"].includes(customPgData?.active_pg_name);
  // Disabling the flow untill reports is fixed - Sasank (commit by arunesh-kumar-sf)
  const enableRefundCard = false;
  const custom_pg_name = customPgData?.active_pg_name;
  const shift4_utg_url = customPgData?.shift4_utg_url;
  const shift4_access_token = customPgData?.shift4_access_token;

  // handling the checkin api
  const [checkinLoading, setCheckinLoading] = useState(false);
  // const handleCheckin = () => {
  //   const balanceDue = Number(summaryData?.balance_due);
  //   const checkInWithoutPayment =
  //     JSON.parse(
  //       data?.accessControl?.user_feature_map?.AllowCheckinWithoutPayment
  //     ).read === "YES";

  //   const checkoutWithoutPayment =
  //     JSON.parse(
  //       data?.accessControl?.user_feature_map?.AllowCheckoutWithoutPayment
  //     ).read === "YES";

  //   const paymentMade = Number(summaryData?.payment_made);

  //   setCheckinLoading(true);

  //   if (validateCheckin()) {
  //     if (balanceDue > 0) {
  //       if (checkInWithoutPayment === "YES") {
  //         if (
  //           JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
  //             .read !== "YES" &&
  //           moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
  //         ) {
  //           toast.error("Past date checkin is not allowed");
  //           setCheckinLoading(false);
  //         } else {
  //           handleSpringDataRequest(
  //             `core/api/v1/reservation/adminConfirmBooking/?bookingId=${bookingId}`
  //           )
  //             .then((res) => updateInvoice())
  //             .then((res) => updateSummaryData())
  //             .then((res) => toast.success("Successfully checked in"))
  //             .then((res) =>
  //               amplitude
  //                 .getInstance()
  //                 .logEvent(
  //                   "USER PREFORMED CHECK-IN OPERATION THROUGH FOLIO PAGE",
  //                   amplitudeEventProperties
  //                 )
  //             )
  //             .then((res) => setCheckinLoading(false))
  //             .then((res) => fetchBookingData())
  //             .catch((err) => {
  //               toast.error(err.response.data);
  //               setCheckinLoading(false);
  //             });
  //         }
  //       } else if (
  //         checkInWithoutPayment === "PARTIAL_PAYMENT" &&
  //         paymentMade === 0
  //       ) {
  //         toast.error("Partial payment necessary to check-in");
  //       } else if (checkInWithoutPayment === "FULL_PAYMENT") {
  //         toast.error("Full Payment Necessary");
  //       } else {
  //         if (
  //           JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
  //             .read !== "YES" &&
  //           moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
  //         ) {
  //           toast.error("Past date checkin is not allowed");
  //           setCheckinLoading(false);
  //         } else {
  //           handleSpringDataRequest(
  //             `core/api/v1/reservation/adminConfirmBooking/?bookingId=${bookingId}`
  //           )
  //             .then((res) => updateInvoice())
  //             .then((res) => updateSummaryData())
  //             .then((res) => toast.success("Successfully checked in"))
  //             .then((res) =>
  //               amplitude
  //                 .getInstance()
  //                 .logEvent(
  //                   "USER PREFORMED CHECK-IN OPERATION THROUGH FOLIO PAGE",
  //                   amplitudeEventProperties
  //                 )
  //             )
  //             .then((res) => setCheckinLoading(false))
  //             .then((res) => fetchBookingData())
  //             .catch((err) => {
  //               toast.error(err.response.data);
  //               setCheckinLoading(false);
  //             });
  //         }
  //       }
  //     } else {
  //       if (
  //         JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
  //           .read !== "YES" &&
  //         moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
  //       ) {
  //         toast.error("Past date checkin is not allowed");
  //         setCheckinLoading(false);
  //       } else {
  //         if (
  //           JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
  //             .read !== "YES" &&
  //           moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
  //         ) {
  //           toast.error("Past date checkin is not allowed");
  //         } else {
  //           handleSpringDataRequest(
  //             `core/api/v1/reservation/adminConfirmBooking/?bookingId=${bookingId}`
  //           )
  //             .then((res) => updateInvoice())
  //             .then((res) => updateSummaryData())
  //             .then((res) => toast.success("Successfully checked in"))
  //             .then((res) =>
  //               amplitude
  //                 .getInstance()
  //                 .logEvent(
  //                   "USER PREFORMED CHECK-IN OPERATION THROUGH FOLIO PAGE",
  //                   amplitudeEventProperties
  //                 )
  //             )
  //             .then((res) => setCheckinLoading(false))
  //             .then((res) => fetchBookingData())
  //             .catch((err) => {
  //               toast.error(err.response.data);
  //               setCheckinLoading(false);
  //             });
  //         }
  //       }
  //     }
  //   } else {
  //     toast.error(
  //       "Checkin is not allowed. Kindly check the check in and check out date."
  //     );
  //     setCheckinLoading(false);
  //   }
  // };

  // void type options

  // const handleCheckin = () => {
  //   const balanceDue = Number(summaryData?.balance_due);
  //   const checkInWithoutPayment =
  //     JSON.parse(
  //       data?.accessControl?.user_feature_map?.AllowCheckinWithoutPayment
  //     ).read === "YES";

  //   const checkoutWithoutPayment =
  //     JSON.parse(
  //       data?.accessControl?.user_feature_map?.AllowCheckoutWithoutPayment
  //     ).read === "YES";

  //   const paymentMade = Number(summaryData?.payment_made);

  //   setCheckinLoading(true);

  //   if (validateCheckin()) {
  //     if (balanceDue > 0) {
  //       if (
  //         JSON.parse(
  //           data?.accessControl?.user_feature_map
  //             ?.AllowCheckinUnrestrictedPayment
  //         ).read === "YES"
  //       ) {
  //         if (
  //           JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
  //             .read !== "YES" &&
  //           moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
  //         ) {
  //           toast.error("Past date checkin is not allowed");
  //           setCheckinLoading(false);
  //         } else {
  //           handleSpringDataRequest(
  //             `core/api/v1/reservation/adminConfirmBooking/?bookingId=${bookingId}`
  //           )
  //             .then((res) => updateInvoice())
  //             .then((res) => updateSummaryData())
  //             .then((res) => toast.success("Successfully checked in"))
  //             .then((res) =>
  //               amplitude
  //                 .getInstance()
  //                 .logEvent(
  //                   "USER PREFORMED CHECK-IN OPERATION THROUGH FOLIO PAGE",
  //                   amplitudeEventProperties
  //                 )
  //             )
  //             .then((res) => setCheckinLoading(false))
  //             .then((res) => fetchBookingData())
  //             .catch((err) => {
  //               toast.error(err.response.data);
  //               setCheckinLoading(false);
  //             });
  //         }
  //       } else if (
  //         JSON.parse(
  //           data?.accessControl?.user_feature_map?.AllowCheckinPartialPayment
  //         ).read === "YES" &&
  //         paymentMade === 0
  //       ) {
  //         toast.error("Partial payment necessary to check-in");
  //       } else if (
  //         JSON.parse(
  //           data?.accessControl?.user_feature_map?.AllowCheckinFullPayment
  //         ).read === "YES"
  //       ) {
  //         toast.error("Full Payment Necessary");
  //       } else {
  //         if (
  //           JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
  //             .read !== "YES" &&
  //           moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
  //         ) {
  //           toast.error("Past date checkin is not allowed");
  //           setCheckinLoading(false);
  //         } else {
  //           handleSpringDataRequest(
  //             `core/api/v1/reservation/adminConfirmBooking/?bookingId=${bookingId}`
  //           )
  //             .then((res) => updateInvoice())
  //             .then((res) => updateSummaryData())
  //             .then((res) => toast.success("Successfully checked in"))
  //             .then((res) =>
  //               amplitude
  //                 .getInstance()
  //                 .logEvent(
  //                   "USER PREFORMED CHECK-IN OPERATION THROUGH FOLIO PAGE",
  //                   amplitudeEventProperties
  //                 )
  //             )
  //             .then((res) => setCheckinLoading(false))
  //             .then((res) => fetchBookingData())
  //             .catch((err) => {
  //               toast.error(err.response.data);
  //               setCheckinLoading(false);
  //             });
  //         }
  //       }
  //     } else {
  //       if (
  //         JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
  //           .read !== "YES" &&
  //         moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
  //       ) {
  //         toast.error("Past date checkin is not allowed");
  //         setCheckinLoading(false);
  //       } else {
  //         if (
  //           JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
  //             .read !== "YES" &&
  //           moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
  //         ) {
  //           toast.error("Past date checkin is not allowed");
  //         } else {
  //           handleSpringDataRequest(
  //             `core/api/v1/reservation/adminConfirmBooking/?bookingId=${bookingId}`
  //           )
  //             .then((res) => updateInvoice())
  //             .then((res) => updateSummaryData())
  //             .then((res) => toast.success("Successfully checked in"))
  //             .then((res) =>
  //               amplitude
  //                 .getInstance()
  //                 .logEvent(
  //                   "USER PREFORMED CHECK-IN OPERATION THROUGH FOLIO PAGE",
  //                   amplitudeEventProperties
  //                 )
  //             )
  //             .then((res) => setCheckinLoading(false))
  //             .then((res) => fetchBookingData())
  //             .catch((err) => {
  //               toast.error(err.response.data);
  //               setCheckinLoading(false);
  //             });
  //         }
  //       }
  //     }
  //   } else {
  //     toast.error(
  //       "Checkin is not allowed. Kindly check the check in and check out date."
  //     );
  //     setCheckinLoading(false);
  //   }
  // };

  const handleCheckin = async () => {
    const balanceDue = Number(summaryData?.balance_due);
    const checkInWithoutPayment =
      JSON.parse(
        data?.accessControl?.user_feature_map?.AllowCheckinWithoutPayment
      ).read === "YES";

    const checkoutWithoutPayment =
      JSON.parse(
        data?.accessControl?.user_feature_map?.AllowCheckoutWithoutPayment
      ).read === "YES";

    const paymentMade = Number(summaryData?.payment_made);

    setCheckinLoading(true);

    //To not allow check-in until booking is confirmed
    if (folioSummary?.invoice?.is_enquiry === "true") {
      toast.error(`${t("Please confirm the booking first, to check in!")}`);
      setCheckinLoading(false);
      return;
    }

    if (!validateCheckin()) {
      toast.error(
        `${t(
          "Checkin is not allowed. Kindly check the check-in and check-out dates."
        )}`
      );
      setCheckinLoading(false);
      return;
    }

    try {
      if (balanceDue > 0) {
        if (
          JSON.parse(
            data?.accessControl?.user_feature_map
              ?.AllowCheckinUnrestrictedPayment
          ).read === "YES"
        ) {
          if (
            JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
              .read !== "YES" &&
            moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
          ) {
            toast.error(`${t("Past date checkin is not allowed")}`);
            setCheckinLoading(false);
          } else {
            await handleConfirmBooking();
            await fetchBookingData();
          }
        } else if (
          JSON.parse(
            data?.accessControl?.user_feature_map?.AllowCheckinPartialPayment
          ).read === "YES" &&
          paymentMade === 0
        ) {
          toast.error(`${t("Partial payment necessary to check-in")}`);
        } else if (
          JSON.parse(
            data?.accessControl?.user_feature_map?.AllowCheckinFullPayment
          ).read === "YES"
        ) {
          toast.error(`${t("Full Payment Necessary")}`);
        } else {
          if (
            JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
              .read !== "YES" &&
            moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
          ) {
            toast.error(`${t("Past date checkin is not allowed")}`);
            setCheckinLoading(false);
          } else {
            await handleConfirmBooking();
            await fetchBookingData();
          }
        }
      } else {
        if (
          JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckin)
            .read !== "YES" &&
          moment(folioSummary?.invoice?.checkin).unix() < currentEpoch
        ) {
          toast.error(`${t("Past date checkin is not allowed")}`);
          setCheckinLoading(false);
        } else {
          await handleConfirmBooking();
          await fetchBookingData();
        }
      }
    } catch (err) {
      toast.error(err.response?.data);
      setCheckinLoading(false);
    } finally {
      setCheckinLoading(false);
    }
  };

  const handleCleanRoom = (roomId) => {
    // console.log(
    //   "folioSummary?.invoice?.room_ids_list",
    //   folioSummary?.invoice?.room_ids_list
    // );
    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-clean`,
        {
          roomIds: roomId,
          staffId: "",
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  const handleCheckCleanStatus = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/get-room-dirty-status`,
      folioSummary?.invoice?.room_ids_list
    ).then((data) => {
      const arr = [];
      Object.keys(data).map((elm) => {
        if (elm !== "UNASSIGNED" && data[elm] === "DIRTY") {
          arr.push(elm);
        }
      });
      arr.length > 0 && handleCleanRoom(arr);
    });
  };

  const handleConfirmBooking = async () => {
    // handleCleanRoom();
    handleCheckCleanStatus();
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/adminConfirmBooking/?bookingId=${bookingId}`
      );
      await handleGetDoorLockDetails();
      Promise.all([updateInvoice(), updateSummaryData()]);
      toast.success(`Successfully checked in ${bookingId}`);
      /* amplitude
        .getInstance()
        .logEvent(
          "USER PERFORMED CHECK-IN OPERATION THROUGH FOLIO PAGE",
          amplitudeEventProperties
        ); */
      fetchBookingData();
    } catch (err) {
      toast.error(err.response?.data);
    } finally {
      setCheckinLoading(false);
    }
  };

  // Log the details for creating the card
  const [makeCardLogs, setMakeCardLogs] = useState({});
  const handleGetDoorLockDetails = async () => {
    if (sessionStorage.getItem(`xytronEnabled${hotelId}`) === "true") {
      try {
        const doorLockDetails = await handleSpringDataRequest(
          `core/api/v1/xytron/get-door-lock-details?bookingId=${bookingId}`
        );
        await handleMakeXytronCard(doorLockDetails);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleMakeXytronCard = async (doorLockDetails) => {
    for (let details of doorLockDetails) {
      try {
        delete details["booking_status"];
        const res = await axios.post(
          "http://127.0.0.1:8081/makecard.php",
          details
        );
        setMakeCardLogs((prev) => ({ ...prev, [details["booking_id"]]: res }));
      } catch (err) {
        setMakeCardLogs((prev) => ({ ...prev, [details["booking_id"]]: err }));
      }
    }
  };

  const [voidTypeOptions, setVoidTypeOptions] = useState([
    {
      name: "NO_VOID",
      description: "Don't void",
    },
    {
      name: "CHARGE_ONE_NIGHT",
      description: "Charge 1 night and void remaining",
    },
    {
      name: "VOID",
      description: "Void all remaining charges",
    },
  ]);
  const [voidTypeOption, setVoidTypeOption] = useState("");
  const handleVoidTypeOption = (e) => {
    setVoidTypeOption(e.target.value);
  };

  // Handle cancellation charge booking API
  const handleVoidCharges = () => {
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/voidCheckoutCharges",
      {
        bookingId: bookingId.toString(),
        voidType: voidTypeOption,
      }
    )
      .then(() => {
        updateInvoice();
        updateSummaryData();
      })
      .catch((err) => toast.error(`${t("Error in voiding charges")}`));
  };

  //open Currenncy Modal

  const [openCurrencyModal, setOpenCurrencyModal] = useState(false);
  const handleOpenCurrencyModal = () => setOpenCurrencyModal(true);
  const handleCloseCurrencyModal = () => setOpenCurrencyModal(false);

  //call to create custom invoice
  const callInvoiceIdGeneration = () => {
    axios.get(
      `${process.env.REACT_APP_SPRING_API_URL}web-api/generate-custom-invoice-id?bookingId=${bookingId}&bookingStatus=${folioSummary?.invoice?.status}`
    );
  };
  /* Booking Checkout */
  // Checkinng out when status is set to ADMIN_CONFIRMED
  // Opening modal for checkout to void status or not
  const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
  const handleOpenCheckoutModal = () => setOpenCheckoutModal(true);
  const handleCloseCheckoutModal = () => setOpenCheckoutModal(false);

  //Early checkout case handling
  const handleCheckoutModal = () => {
    let checkoutDate = moment(
      folioSummary?.invoice?.actual_checkout.replace("T", " "),
      "YYYY-MM-DD HH:mm:ss"
    ).startOf("day");
    let currentDate = moment().startOf("day");
    if (
      data?.accessControl?.user_feature_map?.EarlyCheckoutAlert &&
      JSON.parse(data?.accessControl?.user_feature_map?.EarlyCheckoutAlert)
        .read === "YES" &&
      checkoutDate > currentDate
    ) {
      openEarlyCheckoutModal();
    } else {
      handleOpenCheckoutModal();
    }
  };
  // Checkout loading intializing
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const handleCheckout = async () => {
    const balanceDue = Number(summaryData?.balance_due);
    const checkInWithoutPayment =
      JSON.parse(
        data?.accessControl?.user_feature_map?.AllowCheckinWithoutPayment
      ).read === "YES";

    const checkoutWithoutPayment =
      JSON.parse(
        data?.accessControl?.user_feature_map?.AllowCheckoutWithoutPayment
      ).read === "YES";

    const paymentMade = Number(summaryData?.payment_made);
    if (voidTypeOption === "" && Number(summaryData?.balance_due > 0)) {
      toast.error(
        `${t(
          "You have an outstanding amount. Please select a value before proceeding."
        )}`
      );
    } else {
      if (
        JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckout)
          .read !== "YES" &&
        moment(folioSummary?.invoice?.checkout).unix() < currentEpoch
      ) {
        toast.error(`${t("Past date checkout is not allowed")}`);
      } else {
        if (checkoutWithoutPayment === "NO") {
          if (balanceDue !== 0) {
            toast.error(`${t("Checkout without payment is not allowed")}`);
          } else {
            setCheckoutLoading(true);
            try {
              const res = await axios
                .post(
                  `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/checkout-booking/?bookingId=${bookingId}&releaseInventory=false`,
                  {}
                )
                .then((res) => {
                  setCheckoutLoading(false);
                  if (res.code === "500") {
                    toast.error(res.message);
                  } else {
                    if (Number(summaryData?.balance_due) > 0) {
                      handleVoidCharges();
                    } else {
                      updateInvoice();
                      updateSummaryData();
                    }
                    toast.success(`${t("Successfully checked out")}`);
                  }
                })
                .catch(() => {
                  toast.error(`${t("Error checking out the user")}`);
                  setCheckoutLoading(false);
                });

              // if (Number(summaryData?.balance_due) > 0) {
              //   await handleVoidCharges();
              // }
              // await updateInvoice();
              // await updateSummaryData();
              if (isGrid) {
                fetchBookingData();
              }
            } catch (err) {
              toast.error(`${t("Error checking out the user")}`);
            }
            // finally {
            //   setCheckoutLoading(false);
            // }

            // setCheckoutLoading(true);
            // handleSpringDataMutationRequest(
            //   "POST",
            //   `core/api/v1/reservation/checkout-booking/?bookingId=${bookingId}&releaseInventory=false`,
            //   {}
            // )
            // .then((res) => {
            //   if (res.code === "500") {
            //     toast.error(res.message);
            //   } else {
            //     toast.success("Successfully checked out");
            //   }
            // })
            // .then(
            //   (res) =>
            //     Number(summaryData?.balance_due) > 0 && handleVoidCharges()
            // )
            // .then((res) => updateInvoice())
            // .then((res) => updateSummaryData())
            // .then((res) => setCheckoutLoading(false))
            // .then((res) => (isGrid ? fetchBookingData() : ""))
            // .catch((err) => {
            //   toast.error("Error checking out the user");
            //   setCheckoutLoading(false);
            // });
          }
        } else {
          if (
            JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckout)
              .read !== "YES" &&
            moment(folioSummary?.invoice?.checkout).unix() < currentEpoch
          ) {
            toast.error(`${t("Past date checkout is not allowed")}`);
          } else {
            setCheckoutLoading(true);
            try {
              const res = axios
                .post(
                  `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/checkout-booking/?bookingId=${bookingId}&releaseInventory=false`,
                  {}
                )
                .then((res) => {
                  setCheckoutLoading(false);
                  if (res.code === "500") {
                    toast.error(res.message);
                  } else {
                    // debugger;
                    // console.log("Checkout 2");
                    if (Number(summaryData?.balance_due) > 0) {
                      handleVoidCharges();
                    } else {
                      updateInvoice();
                      updateSummaryData();
                    }
                    toast.success(`${t("Successfully checked out")}`);
                  }
                })
                .catch(() => {
                  toast.error(`${t("Error checking out the user")}`);
                  setCheckoutLoading(false);
                });

              // if (Number(summaryData?.balance_due) > 0) {
              //   await handleVoidCharges();
              // } else {
              //   await updateInvoice();
              //   await updateSummaryData();
              // }

              if (isGrid) {
                fetchBookingData();
              }
            } catch (err) {
              toast.error(`${t("Error checking out the user")}`);
            }
            // finally {
            //   setCheckoutLoading(false);
            // }
          }
        }
      }
    }
  };

  const handleEarlyCheckout = async () => {
    setEarlyCheckoutLoader(true);
    const balanceDue = Number(summaryData?.balance_due);
    const checkInWithoutPayment =
      JSON.parse(
        data?.accessControl?.user_feature_map?.AllowCheckinWithoutPayment
      ).read === "YES";

    const checkoutWithoutPayment =
      JSON.parse(
        data?.accessControl?.user_feature_map?.AllowCheckoutWithoutPayment
      ).read === "YES";

    const paymentMade = Number(summaryData?.payment_made);
    let resIdsList = [];
    if (folioSummary?.invoice?.reservation_room_info_list) {
      folioSummary.invoice.reservation_room_info_list.map((resInfo) => {
        resIdsList.push(resInfo?.reservation_id);
      });
    }
    if (voidTypeOption === "" && Number(summaryData?.balance_due > 0)) {
      toast.error(
        `${t(
          "You have an outstanding amount. Please select a value before proceeding."
        )}`
      );
    } else {
      if (
        JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckout)
          .read !== "YES" &&
        moment(folioSummary?.invoice?.checkout).unix() < currentEpoch
      ) {
        toast.error(`${t("Past date checkout is not allowed")}`);
      } else {
        if (checkoutWithoutPayment === "NO") {
          if (balanceDue !== 0) {
            toast.error(`${t("Checkout without payment is not allowed")}`);
          } else {
            //Peform extend reservation on all reservations
            try {
              // const res = await handleSpringDataMutationRequest(
              //   "POST",
              //   "core/api/v1/reservation/performExtendReservation",
              //   {
              //     checkinDate: moment(newCheckoutDate)
              //       .minutes(0)
              //       .seconds(0)
              //       .format("DD-MM-YYYY HH:mm:ss"),
              //     gAdditionalAmt: 0,
              //     hotelId: hotelId,
              //     resIds: resIdsList,
              //     systemUpdate: true,
              //   }
              // );
              try {
                const res = await axios
                  .post(
                    `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/checkout-booking/?bookingId=${bookingId}&releaseInventory=false`,
                    {}
                  )
                  .then((res) => {
                    setEarlyCheckoutLoader(false);
                    if (res.code === "500") {
                      toast.error(res.message);
                    } else {
                      console.log("Checkout 3");
                      if (Number(summaryData?.balance_due) > 0) {
                        handleVoidCharges();
                      } else {
                        updateInvoice();
                        updateSummaryData();
                      }

                      toast.success(`${t("Successfully checked out")}`);
                    }
                  })
                  .catch(() => {
                    toast.error(`${t("Error checking out the user")}`);
                    setEarlyCheckoutLoader(false);
                  });

                // if (Number(summaryData?.balance_due) > 0) {
                //   await handleVoidCharges();
                // }
                // await updateInvoice();
                // await updateSummaryData();
                if (isGrid) {
                  fetchBookingData();
                }
              } catch (err) {
                toast.error(`${t("Error checking out the user")}`);
              }
              // finally {
              //   setEarlyCheckoutLoader(false);
              // }
            } catch (err) {
              toast.error(
                `${t("Rooms not available. Please re-try some other dates.")}`
              );
            }
          }
        } else {
          if (
            JSON.parse(data?.accessControl?.user_feature_map?.PastDateCheckout)
              .read !== "YES" &&
            moment(folioSummary?.invoice?.checkout).unix() < currentEpoch
          ) {
            toast.error("Past date checkout is not allowed");
          } else {
            setEarlyCheckoutLoader(true);
            try {
              // const res = await handleSpringDataMutationRequest(
              //   "POST",
              //   "core/api/v1/reservation/performExtendReservation",
              //   {
              //     checkinDate: moment(newCheckoutDate)
              //       .minutes(0)
              //       .seconds(0)
              //       .format("DD-MM-YYYY HH:mm:ss"),
              //     gAdditionalAmt: 0,
              //     hotelId: hotelId,
              //     resIds: resIdsList,
              //     systemUpdate: true,
              //   }
              // );
              try {
                const res = await axios
                  .post(
                    `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/checkout-booking/?bookingId=${bookingId}&releaseInventory=false`,
                    {}
                  )
                  .then((res) => {
                    setEarlyCheckoutLoader(false);
                    if (res.code === "500") {
                      toast.error(res.message);
                    } else {
                      console.log("Checkout 4");
                      if (Number(summaryData?.balance_due) > 0) {
                        handleVoidCharges();
                      } else {
                        updateInvoice();
                        updateSummaryData();
                      }
                      toast.success(`${t("Successfully checked out")}`);
                    }
                  })
                  .catch(() => {
                    toast.error(`${t("Error checking out the user")}`);
                    setEarlyCheckoutLoader(false);
                  });

                // if (Number(summaryData?.balance_due) > 0) {
                //   await handleVoidCharges();
                // } else {
                //   await updateInvoice();
                //   await updateSummaryData();
                // }

                if (isGrid) {
                  fetchBookingData();
                }
              } catch (err) {
                toast.error(`${t("Error checking out the user")}`);
              }
              // finally {
              //   setEarlyCheckoutLoader(false);
              // }
            } catch (err) {
              toast.error(`${t("Error checking out the user")}`);
            }
          }
        }
      }
    }
    setEarlyCheckoutLoader(false);
  };

  // open modal for dirty rooms
  const [openDirtyModal, setOpenDirtyModal] = useState(false);
  const handleOpenDirtyMoal = () => setOpenDirtyModal(true);
  const handleCloseDirtyModal = () => setOpenDirtyModal(false);

  /* Release Booking */
  // Releasing the booking when status is set to ON_HOLD

  // Confirmation modal to release the booking
  const [openReleaseBooking, setOpenReleaseBooking] = useState(false);
  const handleOpenReleaseBooking = () => setOpenReleaseBooking(true);
  const handleCloseReleaseBooking = () => setOpenReleaseBooking(false);

  const [releaseBookingLoader, setReleaseBookingLoader] = useState(false);
  const handleReleaseBooking = () => {
    setReleaseBookingLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/release-booking?bookingId=${bookingId}`,
      {}
    )
      .then((res) => updateInvoice())
      .then((res) => updateSummaryData())
      .then((res) => toast.success(`${t("Releasing the booking.")}`))
      .then((res) => setReleaseBookingLoader(false))
      .then((res) => handleCloseReleaseBooking())
      .then((res) => (isGrid ? fetchBookingData() : ""))
      .catch((err) => toast.error(`${t("Error releasing the booking.")}`))
      .catch((err) => setReleaseBookingLoader(false))
      .catch((err) => handleCloseReleaseBooking());
  };

  // handling the format in which data is being sent
  const handleSentDateFormat = (recordDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ==
      moment(recordDate).format("YYYY-MM-DD")
    ) {
      return moment(recordDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return moment(recordDate).format("YYYY-MM-DD") + " 00:00:00";
  };

  // SETTLE DUES
  const [roomHasPosOrders, setRoomHasPosOrders] = useState(false);
  const [currentPaymentSelectionMode, setCurrentPaymentSelectionMode] =
    useState(["Booking amount", "POS amount"]);
  const [settlementType, setSettlementType] = useState("All");
  const [settlementTypeAmount, setSettlementTypeAmount] = useState(
    summaryData?.balance_due
  );
  const [posBalancedDue, setPosBalancedDue] = useState(0);

  const handleGetPosBalancedDue = async () => {
    try {
      const res = await handleSpringDataRequest(
        `core/api/v1/orders/get-pos-balance-due?bookingId=${bookingId}`
      );
      setPosBalancedDue(Number(res));
    } catch (err) {
      console.log("ERROR IN GETTING POS BALANCED DUE");
    }
  };
  // const [displaySettledAmount, setDisplaySettledAmount]
  const checkIfRoomHasPosOrders = () => {
    if (
      folioSummary?.items?.items?.filter(
        (item) =>
          item.pos_order_id !== "" &&
          item?.service_desc?.startsWith("POS ORDER. Invoice no.")
      ).length > 0
    ) {
      if (posBalancedDue > 0) {
        setRoomHasPosOrders(true);
      } else {
        setRoomHasPosOrders(false);
      }
    } else {
      setRoomHasPosOrders(false);
    }
  };

  const handleChangeBookingSettlementType = async () => {
    const currentSettlementAmount = summaryData?.balance_due;
    // const posTotalAmount = folioSummary?.items?.items?.reduce((acc, curr) => {
    //   if (curr?.pos_order_id !== "") {
    //     acc += curr?.service_total || 0;
    //   }
    //   return acc;
    // }, 0);
    const posTotalAmount = posBalancedDue;

    if (
      currentPaymentSelectionMode.length === 2
      // ["Booking amount", "POS amount"].includes(currentPaymentSelectionMode)
    ) {
      setSettlementType("All");
      setSettlementTypeAmount(currentSettlementAmount);
    } else if (
      currentPaymentSelectionMode.length === 1 &&
      currentPaymentSelectionMode[0] === "Booking amount"
    ) {
      setSettlementType("Booking");
      setSettlementTypeAmount(
        (Number(summaryData?.balance_due) - Number(posTotalAmount)).toFixed(2)
      );
    } else if (
      currentPaymentSelectionMode.length === 1 &&
      currentPaymentSelectionMode[0] === "POS amount"
    ) {
      setSettlementType("Pos");
      setSettlementTypeAmount(posTotalAmount.toFixed(2));
    }
  };

  useEffect(() => {
    handleGetPosBalancedDue();
  }, [bookingId, currentPaymentSelectionMode]);

  useEffect(() => {
    if (folioSummary) {
      checkIfRoomHasPosOrders();
    }
  }, [folioSummary, posBalancedDue]);

  useEffect(() => {
    handleChangeBookingSettlementType();
  }, [currentPaymentSelectionMode, posBalancedDue]);

  /* settle dues --- cash payment */

  // accepting cash payment modal
  const [openCashPayment, setOpenCashPayment] = useState(false);
  const handleOpenCashPayment = () => {
    if (
      hotelType === "ISHA" &&
      Number(summaryData?.total_amount_with_services) > 100000
    ) {
      toast.error("Cannot take cash transaction of more than 100000 in cash");
    } else {
      if (
        hotelType === "ISHA" &&
        previewIsha === "" &&
        ereceiptgenerated === "false"
      ) {
        toast.error(
          "Please ensure to preview the E-Receipt before accepting the payment"
        );
      }
      setOpenCashPayment(true);
    }
  };
  const handleCloseCashPayment = () => setOpenCashPayment(false);

  // fields for for cash payment

  const [amount, setAmount] = useState(summaryData?.balance_due);
  const [offlineCashDate, setOfflineCashDate] = useState(new Date());
  const [serviceAmountDesc, setServiceAmountDesc] = useState("");

  // cash payment loading
  const [cashPayLoading, setCashPayLoading] = useState(false);

  // handling the cash payment flows

  // Necessary checks to ensure all the relevant information is filled while taking the cash payment
  function handleCashPaymentValidations() {
    if (hotelType === "ISHA") {
      if (
        serviceAmountDesc?.trim().length === 0 ||
        Number(settlementTypeAmount) <= 0
      ) {
        return true;
      }
    } else {
      if (Number(settlementTypeAmount) <= 0) {
        return true;
      }
    }
    return false;
  }

  async function bulkSettlePosOrders(paymentType) {
    if (roomHasPosOrders && ["All", "Pos"].includes(settlementType)) {
      const posOrderIds = folioSummary?.items?.items
        ?.filter((item) => item.pos_order_id.length !== 0)
        .map((posItem) => posItem.pos_order_id);

      if (posOrderIds.length > 0) {
        await handleSpringDataMutationRequest(
          "POST",
          "core/api/v1/order-payment/bulk-settle",
          {
            booking_id: bookingId,
            payment_type: paymentType.toUpperCase(),
            order_id_list: posOrderIds,
          }
        );
      }
    }
  }

  const handleCashPayment = async () => {
    try {
      setCashPayLoading(true);

      // First, settle the POS orders with CASH
      await bulkSettlePosOrders("CASH");

      // Then, handle the cash payment
      await axios.post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordCashPayment/`,
        {
          hotelId: hotelId,
          bookingID: bookingId,
          reservation_id: selectedResId,
          amount: settlementTypeAmount,
          offline_card_date: handleSentDateFormat(offlineCashDate),
          service_amount_desc: serviceAmountDesc,
          logged_user_email: loggedUserEmail,
        }
      );

      await toast.success(
        `${t("Successfully recorded cash payment")} ${bookingId}`
      );
      // await updateSummaryDataFromCache();
      await updateSummaryData();
      await updateInvoice();
      /* amplitude
        .getInstance()
        .logEvent(
          "USER MADE CASH PAYMENT THROUGH FOLIO PAGE",
          amplitudeEventProperties
        ); */
      await setCashPayLoading(false);
      await setOfflineCashDate(new Date());
      await callInvoiceIdGeneration();
      await setServiceAmountDesc();
      await handleCloseCashPayment();
      if (isGrid) {
        await fetchBookingData();
      }
    } catch (error) {
      setCashPayLoading(false);
      handleCloseCashPayment();
      toast.error(error);
    }
  };

  /* Settle dues --- offline card payment */

  // accepting offline card payment modal

  const [openOfflineCardPay, setOpenOfflineCardPay] = useState(false);
  const handleOpenOfflineCardPay = () => {
    if (
      hotelType === "ISHA" &&
      previewIsha === "" &&
      ereceiptgenerated === "false"
    ) {
      toast.error(
        "Please ensure to preview the E-Receipt before accepting the payment"
      );
    }
    setOpenOfflineCardPay(true);
  };
  const handleCloseOfflineCardPay = () => {
    setterminalId("");
    setOpenOfflineCardPay(false);
  };

  // fields for for offline card payment

  const [offlineCardAmt, setOfflineCardAmt] = useState(
    summaryData?.balance_due
  );
  const [offlineCardDate, setOfflineCardDate] = useState(new Date());
  const [offlineCardDesc, setOfflineCardDesc] = useState();

  // cash payment loading
  const [offlineCardPayLoading, setOfflineCardPayLoading] = useState(false);

  // handling the offline card payment flows

  const handleOfflineCardPayment = async () => {
    let payload;
    if (hotelType === "ISHA") {
      payload = {
        hotelId: hotelId,
        bookingID: bookingId,
        // amount: offlineCardAmt,
        amount: settlementTypeAmount,
        offline_card_date: handleSentDateFormat(offlineCardDate),
        service_amount_desc:
          offlineCardDesc +
          " || CCTerminalID - " +
          terminalId +
          " || ApprovalCode - " +
          approvalCode,
        logged_user_email: loggedUserEmail,
        cc_terminal_id: terminalId,
        cc_approval_code: approvalCode,
        card_holder_name: cardHolder,
      };
    } else {
      payload = {
        hotelId: hotelId,
        bookingID: bookingId,
        reservation_id: selectedResId,
        // amount: offlineCardAmt,
        amount: settlementTypeAmount,
        offline_card_date: handleSentDateFormat(offlineCardDate),
        service_amount_desc: offlineCardDesc,
        logged_user_email: loggedUserEmail,
      };
    }
    if (
      (terminalId === null || terminalId === undefined || terminalId === "") &&
      hotelType === "ISHA"
    ) {
      toast.error(`${t("Please select terminal id")}`);
    } else {
      setOfflineCardPayLoading(true);

      try {
        // Bulk settle POS orders with OFFLINE_CARD payment type
        await bulkSettlePosOrders("OFFLINECARD");

        // Handle offline card payment
        await axios.post(
          `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCardPayment/`,
          payload
        );

        // Update summary and invoice data
        await updateSummaryDataFromCache();
        await updateInvoice();

        // Show success message
        toast.success(
          `${t("Successfully recorded card payment")}  ${bookingId}`
        );

        // Log event
        /* amplitude
          .getInstance()
          .logEvent(
            "USER PERFORMED OFFLINE CARD PAYMENT THROUGH FOLIO PAGE",
            amplitudeEventProperties
          ); */

        // Reset loading state and form fields
        setOfflineCardPayLoading(false);
        setOfflineCardAmt("");
        setOfflineCardDate(new Date());
        setOfflineCardDesc();
        handleCloseOfflineCardPay();

        // Reset additional fields
        setcardHolder("");
        setapprovalCode("");
        setterminalId("");

        // Generate new invoice ID
        await callInvoiceIdGeneration();

        // Fetch booking data if needed
        if (isGrid) {
          await fetchBookingData();
        }
      } catch (error) {
        // Handle errors
        setOfflineCardPayLoading(false);
        handleCloseOfflineCardPay();
        toast.error(error);
      }
    }
  };

  // UPI payment modal loading
  const [openUpi, setOpenUpi] = useState(false);
  const handleOpenUpiPay = () => setOpenUpi(true);
  const handleCloseUpiPay = () => setOpenUpi(false);
  const [upiPayLoading, setUpiPayLoading] = useState(false);

  const [upiDate, setUpiDate] = useState(new Date());

  const [upiAmt, setUpiAmt] = useState(summaryData?.balance_due);
  const [upiDesc, setUpiDesc] = useState("");

  const handleUpiPayment = async () => {
    let payload;

    if (hotelType === "ISHA") {
      payload = {
        hotelId: hotelId,
        bookingID: bookingId,
        reservation_id: selectedResId,
        // amount: Number(upiAmt),
        amount: Number(settlementTypeAmount),
        offline_upi_date: handleSentDateFormat(upiDate),
        service_amount_desc:
          upiDesc +
          " || CCTerminalID - " +
          terminalId +
          " || ApprovalCode - " +
          approvalCode,
        logged_user_email: loggedUserEmail,
        cc_terminal_id: terminalId,
        cc_approval_code: approvalCode,
      };
    } else {
      payload = {
        hotelId: hotelId,
        bookingID: bookingId,
        reservation_id: selectedResId,
        // amount: Number(upiAmt),
        amount: Number(settlementTypeAmount),
        offline_upi_date: handleSentDateFormat(upiDate),
        service_amount_desc: upiDesc,
        logged_user_email: loggedUserEmail,
      };
    }
    try {
      if (
        (terminalId === null ||
          terminalId === undefined ||
          terminalId === "") &&
        hotelType === "ISHA"
      ) {
        toast.error(`${t("Please select terminal id")}`);
      } else {
        setUpiPayLoading(true);

        // First, settle the POS orders with CASH
        await bulkSettlePosOrders("UPI");

        // Handle UPI payment
        await axios.post(
          `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordUPIPayment/`,
          payload
        );

        // Show success message
        toast.success(`${t("Successfully recorded UPI payment")} ${bookingId}`);

        // Update summary and invoice data
        await updateSummaryData();
        await updateInvoice();

        // Log event
        /* amplitude
          .getInstance()
          .logEvent(
            "USER PERFORMED OFFLINE CARD PAYMENT THROUGH FOLIO PAGE",
            amplitudeEventProperties
          ); */

        // Reset loading state and form fields
        setUpiPayLoading(false);
        setUpiAmt("");
        setUpiDate(new Date());
        setUpiDesc("");
        setapprovalCode("");
        setterminalId("");
        callInvoiceIdGeneration();
        handleCloseUpiPay();

        // Fetch booking data if needed
        if (isGrid) {
          await fetchBookingData();
        }
      }
    } catch (error) {
      // Handle errors
      toast.error(error);
      setUpiPayLoading(false);
      handleCloseUpiPay();
    }
  };

  // Bank transfer payment mode modal
  const [openBankTransferModePay, setOpenBankTransferModePay] = useState(false);
  const handleOpenBankTransferModePay = () => setOpenBankTransferModePay(true);
  const handleCloseBankTransferModePay = () =>
    setOpenBankTransferModePay(false);
  const [bankTransferModePayLoading, setBankTransferModePayLoading] =
    useState(false);

  const [bankTransferModeDate, setBankTransferModeDate] = useState(new Date());

  const [bankTransferModeAmt, setBankTransferModeAmt] = useState(
    summaryData?.balance_due
  );
  const [bankTransferModeDesc, setBankTransferModeDesc] = useState("");

  const handleBankTransferPayment = async () => {
    try {
      setBankTransferModePayLoading(true);

      await bulkSettlePosOrders("BANK_TRANSFER");

      // Handle bank transfer payment
      await axios.post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordBankTransferPayment/`,
        {
          hotelId: hotelId,
          bookingID: bookingId,
          reservation_id: selectedResId,
          // amount: bankTransferModeAmt,
          amount: settlementTypeAmount,
          bank_transfer_date: handleSentDateFormat(bankTransferModeDate),
          service_amount_desc: bankTransferModeDesc,
          logged_user_email: loggedUserEmail,
        }
      );

      // Call functions in sequence
      await callInvoiceIdGeneration();
      await updateSummaryDataFromCache();
      await updateInvoice();

      // Show success message
      toast.success(`${t("Successfully recorded bank transfer")} ${bookingId}`);

      // Log event
      /* amplitude
        .getInstance()
        .logEvent(
          "USER PERFORMED BANK TRANSFER PAYMENT THROUGH FOLIO PAGE.",
          amplitudeEventProperties
        ); */

      // Fetch booking data if needed
      if (isGrid) {
        await fetchBookingData();
      }
    } catch (error) {
      // Handle errors
      toast.error(error);
    } finally {
      // Reset form fields and loading state
      setBankTransferModeAmt("");
      setBankTransferModeDate(new Date());
      setBankTransferModeDesc("");
      handleCloseBankTransferModePay();
      setBankTransferModePayLoading(false);
    }
  };

  // Other payment mode modal loading
  const [openOtherModePay, setOpenOtherModePay] = useState(false);
  const handleOpenOtherModePay = () => setOpenOtherModePay(true);
  const handleCloseOtherModePay = () => setOpenOtherModePay(false);
  const [otherModePayLoading, setOtherModePayLoading] = useState(false);

  const [otherModeDate, setOtherModeDate] = useState(new Date());

  const [otherModeAmt, setOtherModeAmt] = useState(summaryData?.balance_due);
  const [otherModeDesc, setOtherModeDesc] = useState("");

  const handleOtherModePayment = async () => {
    try {
      setOtherModePayLoading(true);

      await bulkSettlePosOrders("OTHER");

      // Handle other payment mode
      await axios.post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOtherPayment/`,
        {
          hotelId: hotelId,
          bookingID: bookingId,
          reservation_id: selectedResId,
          // amount: otherModeAmt,
          amount: settlementTypeAmount,
          other_payment_date: handleSentDateFormat(otherModeDate),
          service_amount_desc: otherModeDesc,
          logged_user_email: loggedUserEmail,
        }
      );

      // Update summary and invoice data
      await updateSummaryDataFromCache();
      await updateInvoice();

      // Show success message
      toast.success(
        `${t("Successfully recorded other payment modes")}  ${bookingId}`
      );

      // Log event
      /* amplitude
        .getInstance()
        .logEvent(
          "USER PERFORMED OFFLINE CARD PAYMENT THROUGH FOLIO PAGE",
          amplitudeEventProperties
        ); */

      // Reset loading state and form fields
      setOtherModePayLoading(false);
      setOtherModeAmt("");
      setOtherModeDate(new Date());
      setOtherModeDesc("");
      callInvoiceIdGeneration();
      handleCloseOtherModePay();

      // Fetch booking data if needed
      if (isGrid) {
        await fetchBookingData();
      }
    } catch (error) {
      // Handle errors
      toast.error(error);
      setOtherModePayLoading(false);
      handleCloseOtherModePay();
    }
  };

  /* Direct billing to the CTA */
  // Modal for the direct billing to the CTA
  const [openCta, setOpenCta] = useState(false);
  const handleOpenCta = () => setOpenCta(true);
  const handleCloseCta = () => setOpenCta(false);
  // fields for date to the cta, settle dues amount, and the amount description
  // 1. Calendar
  const [settleCtaDate, setSettleCtaDate] = useState(new Date());
  const handleSettleCtaDate = (newDate) => {
    setSettleCtaDate(newDate);
  };
  // 2. settle dues amount
  const [ctaAmt, setCtaAmt] = useState(summaryData?.balance_due);
  const handleCtaAmt = (event) => {
    setCtaAmt(event.target.value);
  };
  // 3. description
  const [ctaDesc, setCtaDesc] = useState("");
  const handleCtaDesc = (event) => {
    setCtaDesc(event.target.value);
  };

  // cta loader
  const [ctaLoader, setCtaLoader] = useState(false);

  function handleDirectCtaBilling() {
    setCtaLoader(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/addBillToCTAServiceToInvoice/",
      {
        hotelId: hotelId,
        booking_id: bookingId,
        reservation_id: selectedResId,
        bill_to_cta_date: moment(settleCtaDate).format("YYYY-MM-DD"),
        bill_to_cta_amt: ctaAmt,
        bill_to_cta_amt_desc: ctaDesc,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) => updateSummaryData())
      .then((res) => updateInvoice())
      .then((res) => toast.success(`${t("Operation successful.")}`))
      /* .then((res) =>
        amplitude
          .getInstance()
          .logEvent(
            "USER PREFORMED DIRECT BILLING OPERATION THROUGH FOLIO PAGE",
            amplitudeEventProperties
          )
      ) */
      .then((res) => setCtaLoader(false))
      .then((res) => handleCloseCta())
      .then((res) => callInvoiceIdGeneration())
      .then((res) => (isGrid ? fetchBookingData() : ""))
      .catch((err) => setCtaLoader(false))
      .catch((err) => handleCloseCta())
      .catch((err) => toast.error(err));
  }

  // Getting all the customer saved cards
  // console.log("user email invoice summ : ", userEmail);

  const [savedCards, setSavedCards] = useState(null);
  // const getSavedCards = () => {
  //   handleSpringDataRequest(
  //     `core/api/v1/customer-card/get-saved-customer-card?email=${userEmail}&bookingId=${bookingId}`
  //   )
  //     .then((res) => setSavedCards(res))
  //     .then((res) => fetchBookingData())
  //     .catch((err) => toast.error(err));
  // };
  const getSavedCards = () => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/customer-card/get-saved-customer-card?bookingId=${bookingId}`,
      { email: userEmail }
    )
      .then((res) => setSavedCards(res))
      .then((res) => fetchBookingData())
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    if (userEmail) {
      getSavedCards();
    }
  }, [bookingId, userEmail]);

  // Getting the total of the services tax
  const getTotalServiceTax = () => {
    var serviceTax = 0;
    const data = folioSummary?.invoice.items.map((item) => {
      String(item.service_id) !== "0" &&
        item.service_amount_type === "DEBIT" &&
        (serviceTax += Number(item.service_tax));
    });
    return Number(serviceTax).toFixed(2);
  };

  // For ISHA --- if customer address is not there, then don't let cash payment
  // and card payment
  const checkIfAddress = () => {
    if (
      folioSummary.invoice.customer_address.customer_address_line1.length > 0 &&
      folioSummary?.invoice.customer_address.customer_address_line1 !== "NA" &&
      folioSummary?.invoice?.customer_email &&
      folioSummary?.invoice?.customer_email.length > 0 &&
      folioSummary?.invoice.customer_phone &&
      folioSummary?.invoice.customer_phone.length > 0 &&
      folioSummary.invoice.customer_address.customer_address_city.length > 0 &&
      folioSummary?.invoice.customer_address.customer_address_city !== "NA" &&
      folioSummary.invoice.customer_address.customer_address_state.length > 0 &&
      folioSummary?.invoice.customer_address.customer_address_state !== "NA" &&
      folioSummary.invoice.customer_address.customer_address_country.length >
        0 &&
      folioSummary?.invoice.customer_address.customer_address_country !==
        "NA" &&
      folioSummary.invoice.customer_address.customer_address_zip.length > 0 &&
      folioSummary?.invoice.customer_address.customer_address_zip !== "NA"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const ishaFutureCheckinDate = () => {
    const checkinDate = moment(folioSummary?.invoice?.checkin).format(
      "YYYY-MM-DD"
    );
    const todaysDate = moment(new Date()).format("YYYY-MM-DD");

    if (moment(checkinDate).isAfter(todaysDate)) {
      return false;
    } else {
      return true;
    }
  };

  const [openAddressAlert, setOpenAddressAlert] = useState(false);
  const handleOpenAddressAlert = () => {
    return setOpenAddressAlert(true);
  };
  const handleCloseAddressAlert = () => {
    return setOpenAddressAlert(false);
  };

  /*SHIFT4 multi terminal support
   * Get the specific terminal details if multiple terminals are configured
   * */
  function getShift4TerminalDetails(custom_pg_terminal_id) {
    let terminals = customPgData?.terminals;
    let shift4_utg_url = "";
    let shift4_api_terminal_id = "";
    terminals.forEach((element) => {
      if (custom_pg_terminal_id === element.id) {
        shift4_utg_url = element.shift4_utg_url;
        shift4_api_terminal_id = element.shift4_api_terminal_id;
      }
    });
    return {
      shift4_utg_url: shift4_utg_url,
      shift4_api_terminal_id: shift4_api_terminal_id,
    };
  }

  /* Settle dues --- offline cheque payment */

  // Rendering the settle dues component
  // Case1 : When any other CTA is present along with the settle dues --- CONFIRMED, CANCELLED, NO SHOW, ON HOLD
  // Case2 : When only settle dues is there --- CHECKED OUT

  // Case1 --->
  const settleDuesComp = () => {
    return (
      <ClickAwayListenerHelper
        clickAwayButtonName="Settle dues"
        btnClassName="settleDuesBtn"
        content={
          <div className="settleDuesActions">
            {JSON.parse(data?.accessControl?.user_feature_map?.CashPayment)
              .read === "YES" && (
              <div
                className="item"
                onClick={() =>
                  hotelType === "ISHA"
                    ? checkIfAddress()
                      ? ishaFutureCheckinDate()
                        ? handleOpenCashPayment()
                        : toast.error(
                            `${t(
                              "Invoice cannot be settled for future checkin"
                            )}`
                          )
                      : handleOpenAddressAlert()
                    : handleOpenCashPayment()
                }
              >
                {t("Cash payment")}
              </div>
            )}
            {JSON.parse(
              data?.accessControl?.user_feature_map?.OfflineCardPayment
            ).read === "YES" && (
              <div
                className="item"
                onClick={() =>
                  hotelType === "ISHA"
                    ? checkIfAddress()
                      ? ishaFutureCheckinDate()
                        ? handleOpenOfflineCardPay()
                        : toast.error(
                            `${t(
                              "Invoice cannot be settled for future checkin"
                            )}`
                          )
                      : handleOpenAddressAlert()
                    : handleOpenOfflineCardPay()
                }
              >
                {t("Offline card payment")}
              </div>
            )}
            {hotelType !== "ISHA" &&
              JSON.parse(
                data?.accessControl?.user_feature_map?.OfflineChequePayment
              ).read === "YES" && (
                <div
                  className="item"
                  onClick={() => handleOpenOfflineCheckPay()}
                >
                  {t("Offline cheque payment")}
                </div>
              )}
            {folioSummary.hotel.hotel_country?.toUpperCase() === "INDIA" &&
              hotelType === "ISHA" && (
                <div
                  className="item"
                  onClick={() =>
                    hotelType === "ISHA"
                      ? checkIfAddress()
                        ? ishaFutureCheckinDate()
                          ? handleOpenUpiPay()
                          : toast.error(
                              "Invoice cannot be settled for future checkin"
                            )
                        : handleOpenAddressAlert()
                      : handleOpenUpiPay()
                  }
                >
                  {t("UPI payment")}
                </div>
              )}
            {hotelType !== "ISHA" &&
              JSON.parse(
                data?.accessControl?.user_feature_map?.OtherPaymentModes
              ).read === "YES" && (
                <div
                  className="item"
                  onClick={() =>
                    hotelType === "ISHA"
                      ? checkIfAddress()
                        ? ishaFutureCheckinDate()
                          ? handleOpenUpiPay()
                          : toast.error(
                              "Invoice cannot be settled for future checkin"
                            )
                        : handleOpenAddressAlert()
                      : handleOpenUpiPay()
                  }
                >
                  {t("UPI payment")}
                </div>
              )}
            {JSON.parse(
              data?.accessControl?.user_feature_map?.OtherPaymentModes
            ).read === "YES" && (
              <div
                className="item"
                onClick={() => handleOpenBankTransferModePay()}
              >
                {t("Bank transfer")}
              </div>
            )}
            {hotelType !== "ISHA" &&
              JSON.parse(
                data?.accessControl?.user_feature_map?.OtherPaymentModes
              ).read === "YES" && (
                <div className="item" onClick={() => handleOpenOtherModePay()}>
                  {t("Other payment modes")}
                </div>
              )}
            {hotelType !== "ISHA" &&
              JSON.parse(data?.accessControl?.user_feature_map?.CashDeposit)
                .read === "YES" && (
                <div className="item" onClick={() => handleOpenCashDeposit()}>
                  {t("Cash deposit")}
                </div>
              )}
            <div className="horizontalSep" />
            {JSON.parse(
              data?.accessControl?.user_feature_map?.ViewManagePayments
            ).read === "YES" && (
              <div
                className="item"
                onClick={() => handleOpenViewManagePayments()}
              >
                {t("View/Manage payments")}
              </div>
            )}
            {folioSummary.hotel.hotel_country?.toUpperCase() !== "INDIA" &&
              data?.accessControl?.hotel?.pg_account_id !== "" &&
              hotelType !== "ISHA" &&
              JSON.parse(data?.accessControl?.user_feature_map?.ChargeCard)
                .read === "YES" && (
                <div
                  className="item"
                  onClick={() => openPerformOnlinePaymentModal()}
                >
                  {t("Gateway Payment (Stripe)")}
                </div>
              )}
            {(hotelType !== "ISHA" || hotelId === "24740") &&
            JSON.parse(data?.accessControl?.user_feature_map?.PaymentLink)
              .read === "YES" ? (
              <>
                {folioSummary?.invoice?.is_enquiry === "true" ? (
                  <>
                    {moment(
                      folioSummary?.invoice?.enquiry_expiration_time
                    ).unix() > moment().unix() && (
                      <div
                        className="item"
                        onClick={() => handleOpenSendPaymentLink()}
                      >
                        {t("Send payment link")}
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className="item"
                    onClick={() => handleOpenSendPaymentLink()}
                  >
                    {t("Send payment link")}
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            {isAdmin === "ADMIN" &&
              custom_pg_name !== "SHIFT4" &&
              Object.keys(otaBookingInfoData) &&
              otaBookingInfoData?.card_num &&
              JSON.parse(data?.accessControl?.user_feature_map?.ChargeCard)
                .read === "YES" && (
                <div className="item" onClick={() => handleOpenOTAAddedCard()}>
                  {t("Charge")} {otaBookingInfoData?.card_holder_first_name}{" "}
                  {otaBookingInfoData?.card_holder_last_name} CARD **
                  {otaBookingInfoData?.card_num?.slice(12, 16)} -{" "}
                  {otaBookingInfoData?.source}
                </div>
              )}
            {isAdmin !== "ADMIN" &&
              custom_pg_name !== "SHIFT4" &&
              paymentViewAccess === "YES" &&
              Object.keys(otaBookingInfoData) &&
              otaBookingInfoData?.card_num &&
              JSON.parse(data?.accessControl?.user_feature_map?.ChargeCard)
                .read === "YES" && (
                <div className="item" onClick={() => handleOpenOTAAddedCard()}>
                  {t("Charge")} {otaBookingInfoData?.card_holder_first_name}{" "}
                  {otaBookingInfoData?.card_holder_last_name} CARD **
                  {otaBookingInfoData?.card_num?.slice(12, 16)} -{" "}
                  {otaBookingInfoData?.source}
                </div>
              )}
            <div className="horizontalSep" />
            {(enableAddCard ||
              (folioSummary.hotel.hotel_country?.toUpperCase() !== "INDIA" &&
                stripeAccount !== "")) &&
              JSON.parse(data?.accessControl?.user_feature_map?.AddCard)
                .read === "YES" && (
                <div className="item" onClick={() => handleOpenAddCard()}>
                  {t("Add card")}
                </div>
              )}
            {enablePerformEMVTxn &&
              JSON.parse(data?.accessControl?.user_feature_map?.PerformEMV)
                .read === "YES" && (
                <div
                  className="item"
                  onClick={() => handleOpenPerformEMVTransaction()}
                >
                  {t("Perform EMV transaction")}
                </div>
              )}
            {data?.country?.toLowerCase() === "india(hold)" && (
              <div
                className="item"
                onClick={() => setOpenPerformRazorpayPOSTransaction(true)}
              >
                {t("Razorpay POS device payment")}
              </div>
            )}
            {(enableAddCard || stripeAccount !== "") &&
              savedCards?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="item"
                    onClick={() => {
                      handleOpenAddedCard(item);
                    }}
                  >
                    {item?.is_emv === "YES" ? "EMV " : ""}
                    {item.card_first_name} {t("CARD")} **{item.card_last4}
                  </div>
                );
              })}
            {folioSummary?.invoice.cta_details && (
              <>
                <div className="horizontalSep" />
                <div className="item" onClick={() => handleOpenCta()}>
                  {t("Direct bill to")} -{" "}
                  {folioSummary?.invoice.booking_source_displayname}
                </div>
              </>
            )}
          </div>
        }
        right="75px"
        bottom="10px"
      />
    );
  };

  // Case 2.
  const settleDuesCompLg = () => {
    return (
      <ClickAwayListenerHelper
        clickAwayButtonName="Settle dues"
        btnClassName="settleDuesBtnLarge"
        content={
          <div className="settleDuesActions">
            {JSON.parse(data?.accessControl?.user_feature_map?.CashPayment)
              .read === "YES" && (
              <div
                className="item"
                onClick={() =>
                  hotelType === "ISHA"
                    ? checkIfAddress()
                      ? ishaFutureCheckinDate()
                        ? handleOpenCashPayment()
                        : toast.error(
                            `${t(
                              "Invoice cannot be settled for future checkin"
                            )}`
                          )
                      : handleOpenAddressAlert()
                    : handleOpenCashPayment()
                }
              >
                {t("Cash payment")}
              </div>
            )}
            {JSON.parse(
              data?.accessControl?.user_feature_map?.OfflineCardPayment
            ).read === "YES" && (
              <div
                className="item"
                onClick={() =>
                  hotelType === "ISHA"
                    ? checkIfAddress()
                      ? ishaFutureCheckinDate()
                        ? handleOpenOfflineCardPay()
                        : toast.error(
                            `${t(
                              "Invoice cannot be settled for future checkin"
                            )}`
                          )
                      : handleOpenAddressAlert()
                    : handleOpenOfflineCardPay()
                }
              >
                {t("Offline card payment")}
              </div>
            )}
            {hotelType !== "ISHA" &&
              JSON.parse(
                data?.accessControl?.user_feature_map?.OfflineChequePayment
              ).read === "YES" && (
                <div
                  className="item"
                  onClick={() => handleOpenOfflineCheckPay()}
                >
                  {t("Offline cheque payment")}
                </div>
              )}
            {folioSummary.hotel.hotel_country?.toUpperCase() === "INDIA" &&
              hotelType !== "ISHA" && (
                <div className="item" onClick={() => handleOpenUpiPay()}>
                  {t("UPI payment")}
                </div>
              )}
            {JSON.parse(
              data?.accessControl?.user_feature_map?.OtherPaymentModes
            ).read === "YES" && (
              <div
                className="item"
                onClick={() => handleOpenBankTransferModePay()}
              >
                {t("Bank transfer")}
              </div>
            )}
            {hotelType !== "ISHA" &&
              JSON.parse(
                data?.accessControl?.user_feature_map?.OtherPaymentModes
              ).read === "YES" && (
                <div className="item" onClick={() => handleOpenOtherModePay()}>
                  {t("Other payment modes")}
                </div>
              )}
            {hotelType !== "ISHA" &&
              JSON.parse(data?.accessControl?.user_feature_map?.CashDeposit)
                .read === "YES" && (
                <div className="item" onClick={() => handleOpenCashDeposit()}>
                  {t("Cash deposit")}
                </div>
              )}
            <div className="horizontalSep" />
            {JSON.parse(
              data?.accessControl?.user_feature_map?.ViewManagePayments
            ).read === "YES" && (
              <div
                className="item"
                onClick={() => handleOpenViewManagePayments()}
              >
                {t("View/Manage payments")}
              </div>
            )}
            {hotelType !== "ISHA" &&
            JSON.parse(data?.accessControl?.user_feature_map?.PaymentLink)
              .read === "YES" ? (
              <>
                {folioSummary?.invoice?.is_enquiry === "true" ? (
                  <>
                    {moment(
                      folioSummary?.invoice?.enquiry_expiration_time
                    ).unix() > moment().unix() && (
                      <div
                        className="item"
                        onClick={() => handleOpenSendPaymentLink()}
                      >
                        {t("Send payment link")}
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className="item"
                    onClick={() => handleOpenSendPaymentLink()}
                  >
                    {t("Send payment link")}
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            {isAdmin === "ADMIN" &&
              custom_pg_name !== "SHIFT4" &&
              Object.keys(otaBookingInfoData) &&
              otaBookingInfoData?.card_num &&
              JSON.parse(data?.accessControl?.user_feature_map?.ChargeCard)
                .read === "YES" && (
                <div className="item" onClick={() => handleOpenOTAAddedCard()}>
                  {t("Charge")} {otaBookingInfoData?.card_holder_first_name}{" "}
                  {otaBookingInfoData?.card_holder_last_name} CARD **
                  {otaBookingInfoData?.card_num?.slice(12, 16)} -{" "}
                  {otaBookingInfoData?.source}
                </div>
              )}
            {isAdmin !== "ADMIN" &&
              custom_pg_name !== "SHIFT4" &&
              paymentViewAccess === "YES" &&
              Object.keys(otaBookingInfoData) &&
              otaBookingInfoData?.card_num &&
              JSON.parse(data?.accessControl?.user_feature_map?.ChargeCard)
                .read === "YES" && (
                <div className="item" onClick={() => handleOpenOTAAddedCard()}>
                  {t("Charge")} {otaBookingInfoData?.card_holder_first_name}{" "}
                  {otaBookingInfoData?.card_holder_last_name} CARD **
                  {otaBookingInfoData?.card_num?.slice(12, 16)} -{" "}
                  {otaBookingInfoData?.source}
                </div>
              )}
            <div className="horizontalSep" />
            {enableAddCard &&
              JSON.parse(data?.accessControl?.user_feature_map?.AddCard)
                .read === "YES" && (
                <div className="item" onClick={() => handleOpenAddCard()}>
                  {t("Add card")}
                </div>
              )}
            {enablePerformEMVTxn &&
              JSON.parse(data?.accessControl?.user_feature_map?.PerformEMV)
                .read === "YES" && (
                <div
                  className="item"
                  onClick={() => handleOpenPerformEMVTransaction()}
                >
                  {t("Perform EMV transaction")}
                </div>
              )}
            {(enableAddCard || stripeAccount !== "") &&
              savedCards?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="item"
                    onClick={() => {
                      handleOpenAddedCard(item);
                    }}
                  >
                    {item?.is_emv === "YES" ? "EMV " : ""}
                    {item.card_first_name} {t("CARD")}**{item.card_last4}
                  </div>
                );
              })}
            {(enableAddCard || stripeAccount !== "") &&
              folioSummary?.saved_cards_own?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="item"
                    onClick={() => handleOpenAddedCard(item)}
                  >
                    {item.card_first_name} {t("CARD")}**{item.card_last4}
                  </div>
                );
              })}
            {folioSummary?.invoice?.cta_details && (
              <>
                <div className="horizontalSep" />
                <div className="item" onClick={() => handleOpenCta()}>
                  {t("Direct bill to")} -{" "}
                  {folioSummary?.invoice.booking_source_displayname}
                </div>
              </>
            )}
          </div>
        }
        right="200px"
        bottom="0px"
      />
    );
  };

  // accepting offline cheque payment modal

  const [openOfflineCheckPay, setOpenOfflineCheckPay] = useState(false);
  const handleOpenOfflineCheckPay = () => setOpenOfflineCheckPay(true);
  const handleCloseOfflineCheckPay = () => setOpenOfflineCheckPay(false);

  // fields for for offline cheque payment

  const [offlineCheckAmt, setOfflineCheckAmt] = useState(
    summaryData?.balance_due
  );
  const [offlineCheckDate, setOfflineCheckDate] = useState(new Date());
  const [chequeDesc, setChequeDesc] = useState();

  // cheque pay loading
  const [offlineCheckPayLoading, setOfflineCheckPayLoading] = useState(false);

  // handling the offline cheque payment flows

  const handleOfflineCheckPayment = async () => {
    try {
      setOfflineCheckPayLoading(true);

      await bulkSettlePosOrders("OFFLINE_CHEQUE");

      // Handle offline check payment
      await axios.post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/recordOfflineCheckPayment/`,
        {
          hotelId: hotelId,
          booking_id: bookingId,
          reservation_id: selectedResId,
          // amount: offlineCheckAmt,
          amount: settlementTypeAmount,
          offline_card_date: handleSentDateFormat(offlineCardDate),
          logged_user_email: loggedUserEmail,
          service_amount_desc: chequeDesc,
        }
      );

      // Update summary and invoice data
      await updateSummaryData();
      await updateInvoice();

      // Show success message
      toast.success(
        `${t("Successfully recorded cheque payment")} ${bookingId}`
      );

      // Log event
      /* amplitude
        .getInstance()
        .logEvent(
          "USER PREFORMED OFFLINE CHECK PAYMENT THROUGH FOLIO PAGE",
          amplitudeEventProperties
        ); */

      // Reset loading state and form fields
      setOfflineCheckPayLoading(false);
      setOfflineCheckAmt("");
      setOfflineCheckDate(new Date());
      setChequeDesc();
      handleCloseOfflineCheckPay();

      // Generate new invoice ID
      callInvoiceIdGeneration();
    } catch (error) {
      // Handle errors
      toast.error(error);
      setOfflineCheckPayLoading(false);
      handleCloseOfflineCheckPay();
    }
  };

  /* Settle dues --- cash deposit */

  // handling cash deposit date format
  const handleOpenCashDepositDate = (cashDepositDate) => {
    const currentDate = new Date();
    if (
      moment(currentDate).format("YYYY-MM-DD") ===
      moment(cashDepositDate).format("YYYY-MM-DD")
    ) {
      return moment(cashDepositDate).format("MMM DD YYYY HH:mm:ss");
    }
    return moment(cashDepositDate).format("MMM DD YYYY") + " 00:00:00";
  };

  // cash deposit modal

  const [openCashDeposit, setOpenCashDeposit] = useState(false);
  const handleOpenCashDeposit = () => setOpenCashDeposit(true);
  const handleCloseCashDeposit = () => setOpenCashDeposit(false);

  // fields for cash deposit

  const [depositAmt, setDepositAmt] = useState(0);
  const [depositDate, setDepositDate] = useState(new Date());
  const [depositName, setDepositName] = useState();

  // deposit loading
  const [depositLoading, setDepositLoading] = useState(false);

  // handling the cash deposit

  const handleDeposit = () => {
    setDepositLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/addServiceToInvoice/`,
        {
          hotelId: hotelId,
          booking_id: bookingId,
          reservation_id: selectedResId,
          service_item: {
            service_date: handleOpenCashDepositDate(depositDate),
            service_amount: depositAmt,
            service_desc: depositName,
            service_amount_type: "DEPOSIT",
          },
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => updateSummaryDataFromCache())
      .then((res) => updateInvoice())
      .then((res) =>
        toast.success(`${t("Successfully recorded deposit")} ${bookingId}`)
      )
      /* .then((res) =>
        amplitude
          .getInstance()
          .logEvent(
            "USER PREFORMED CASH DEPOSIT OPERATION THROUGH FOLIO PAGE",
            amplitudeEventProperties
          )
      ) */
      .then((res) => setDepositLoading(false))
      .then((res) => setDepositAmt(""))
      .then((res) => setDepositDate(new Date()))
      .then((res) => setDepositName())
      .then((res) => handleCloseCashDeposit())
      .catch((error) => setDepositLoading(false))
      .catch((error) => handleCloseCashDeposit())
      .catch((error) => toast.error(error));
  };

  /* Settle dues --- view/Manage Link */

  // view/Manage Link modal
  const [paymentType, setPaymentType] = useState("gateway");
  const [paymentLogData, setPaymentLogData] = useState();
  const [pgName, setPgName] = useState("");
  const [viewManageView, setViewManageView] = useState(0);
  const handlePaymentLogData = () => {
    djangoHandleDataRequests(
      `invoice/bookingInvoicePaymentLogReport?hotelId=${hotelId}&bookingID=${bookingId}&paymentType=${paymentType}`
    )
      .then((res) => {
        setPaymentLogData(res?.invoice?.payment_data);
        setPgName(res?.hotel?.hotel_pgname);
      })
      /* .then((res) =>
        amplitude
          .getInstance()
          .logEvent(
            "USER VISITED VIEW AND MANAGE PAYMENT PAGE THROUGH FOLIO PAGE",
            amplitudeEventProperties
          )
      ) */
      .catch((error) => toast.error(`${t("Error in deleting the card.")}`));
  };

  const [openViewManagePayments, setOpenViewManagePayments] = useState(false);
  const handleOpenViewManagePayments = () => {
    setOpenViewManagePayments(true);
    handlePaymentLogData();
  };
  useEffect(() => {
    handlePaymentLogData();
  }, [viewManageView, paymentType]);
  const handleCloseViewManagePayments = () => {
    setPaymentLogData();
    updateInvoice();
    updateSummaryData();
    setOpenViewManagePayments(false);
  };

  /* Settle dues --- paymentLink */

  // paymentLink modal

  const [openSendPaymentLink, setOpenSendPaymentLink] = useState(false);
  const handleOpenSendPaymentLink = () => setOpenSendPaymentLink(true);
  const handleCloseSendPaymentLink = () => setOpenSendPaymentLink(false);

  // fields for for paymentLink

  const [paymentLinkEmail, setPaymentLinkEmail] = useState();
  const [paymentLinkMobile, setPaymentLinkMobile] = useState();
  const [paymentLinkAmt, setPaymentLinkAmt] = useState();

  // paymentLink loading
  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);

  useEffect(() => {
    setPaymentLinkEmail(folioSummary?.invoice?.customer_email);
    setPaymentLinkMobile(folioSummary?.invoice?.customer_phone);
    setPaymentLinkAmt(summaryData?.balance_due);
  }, [folioSummary, bookingId]);

  // handling the payment link

  // sending customer payment link
  function handlePaymentLink() {
    setPaymentLinkLoading(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendInvoicePaymentCustEmail/",
      {
        hotel_id: hotelId,
        booking_id: bookingId,
        reservation_id: selectedResId,
        email: paymentLinkEmail,
        phone: paymentLinkMobile,
        amount: paymentLinkAmt,
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) =>
        toast.success(`${t("Successfully sent payment link")} ${bookingId}`)
      )
      /* .then((res) =>
        amplitude
          .getInstance()
          .logEvent(
            "USER PREFORMED SEND PAYMENT LINK OPERATION THROUGH FOLIO PAGE",
            amplitudeEventProperties
          )
      ) */
      .then((res) => setPaymentLinkLoading(false))
      .then((res) => handleCloseSendPaymentLink())
      .catch((err) => setPaymentLinkLoading(false))
      .catch((err) => toast.error(err));
  }

  /* Settle dues --- add Card */

  // add Card modal

  const [openAddCard, setOpenAddCard] = useState(false);
  const handleOpenAddCard = () => setOpenAddCard(true);
  const handleCloseAddCard = () => setOpenAddCard(false);

  /* Settle dues --- perform EMV transaction */

  // perform EMV transaction modal

  const [openPerformEMVTransaction, setOpenPerformEMVTransaction] =
    useState(false);
  const handleOpenPerformEMVTransaction = () =>
    setOpenPerformEMVTransaction(true);
  const handleClosePerformEMVTransaction = () =>
    setOpenPerformEMVTransaction(false);

  // fields for for perform EMV transaction
  const [performEMVTransactionAmt, setPerformEMVTransactionAmt] = useState();
  const [performEMVTransactionSaveCard, setPerformEMVTransactionSaveCard] =
    useState(true);
  const [emvTerminalId, setEmvTerminalId] = useState(null);
  useEffect(() => {
    const terminalIds = () => {
      if (customPgData.hasOwnProperty("terminals")) {
        setEmvTerminalId(customPgData.terminals[0].id);
      }
    };
    terminalIds();
  }, [hotelId]);

  // perform EMV transaction loading
  const [performEMVTransactionLoading1, setPerformEMVTransactionLoading1] =
    useState(false);
  const [performEMVTransactionLoading2, setPerformEMVTransactionLoading2] =
    useState(false);
  const [performEMVTransactionLoading3, setPerformEMVTransactionLoading3] =
    useState(false);

  // handling the perform EMV transaction flow

  const handlePerformEMVTransactionCharge = async () => {
    setPerformEMVTransactionLoading1(true);
    data = {
      hotelId: hotelId,
      bookingID: bookingId,
      reservation_id: selectedResId,
      cust_email: folioSummary?.invoice?.customer_email,
      name: folioSummary?.invoice?.customer_name,
      is_emv: "YES",
      trans_type: "sale",
      add_card: performEMVTransactionSaveCard,
      amount: Number(performEMVTransactionAmt),
      logged_user_email: loggedUserEmail,
    };
    if (custom_pg_name === "SHIFT4" && shift4_utg_url) {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/generateShift4UtgRequest/",
        data
      )
        .then((shift4_utg_request) => {
          console.log(shift4_utg_request);
          let URL = shift4_utg_url + "api/rest/v1/transactions/sale";
          if (emvTerminalId) {
            data["terminal_id"] = emvTerminalId;
            let shift4_terminal_info = getShift4TerminalDetails(emvTerminalId);
            URL =
              shift4_terminal_info.shift4_utg_url +
              "api/rest/v1/transactions/sale";
            shift4_utg_request["device"]["terminalId"] =
              shift4_terminal_info.shift4_api_terminal_id;
          }

          let myHeaders = new Headers();
          myHeaders.append("InterfaceVersion", "4.0");
          myHeaders.append("InterfaceName", "Stayflexi");
          myHeaders.append("CompanyName", "Stayflexi");
          myHeaders.append("AccessToken", shift4_access_token);
          myHeaders.append("Content-Type", "application/json");

          let raw = JSON.stringify(shift4_utg_request);
          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(URL, requestOptions)
            .then((response) => {
              return response.json().then((json) => {
                return response.ok
                  ? json
                  : Promise.reject({ status: response.status, json });
              });
            })
            .then((result) => {
              console.log(result);
              data["shift4_utg_response"] = result;
              data["shift4_utg_request"] = shift4_utg_request;
              djangoHandleDataMutationRequest(
                "POST",
                "invoice/recordShift4UtgResponse/",
                data
              )
                .then((res) => updateSummaryDataFromCache())
                .then((res) => updateInvoice())
                .then((res) => callInvoiceIdGeneration())
                .then((res) => {
                  console.log(res);
                  toast.success(`${t("EMV Card charged successfully")}`, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                })
                .then((res) => setPerformEMVTransactionLoading1(false))
                .then((res) => handleClosePerformEMVTransaction())
                .then((res) => setPerformEMVTransactionAmt())
                .then((res) => setPerformEMVTransactionSaveCard(true))
                .catch((error) => {
                  toast.error(error, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                  setPerformEMVTransactionLoading1(false);
                });
            })
            .catch((error) => {
              setPerformEMVTransactionLoading1(false);
              if (
                error.json &&
                error.json.result &&
                error.json.result.length > 0 &&
                error.json.result[0].error
              ) {
                console.log(error);
                console.log(error.json.result[0].error.longText);
                console.error(error.json);
                toast.error(
                  "Transaction failed. Gateway message: " +
                    error.json.result[0].error.longText,
                  {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  }
                );
              } else {
                console.log(error);
                toast.error("Failed to charge the card", {
                  closeOnClick: true,
                  pauseOnHover: true,
                  autoClose: 8000,
                  hideProgressBar: true,
                  bodyClassName: "importantToast",
                });
              }
            });
        })
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading1(false);
        });
    } else {
      if (emvTerminalId) {
        data["terminal_id"] = emvTerminalId;
      }
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/chargeCustomerCardOwn/",
        data
      )
        .then((res) => updateSummaryDataFromCache())
        .then((res) => updateInvoice())
        .then((res) => callInvoiceIdGeneration())
        .then((res) => {
          toast.success(`${t("EMV Card charged successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => setPerformEMVTransactionLoading1(false))
        .then((res) => handleClosePerformEMVTransaction())
        .then((res) => setPerformEMVTransactionAmt())
        .then((res) => setPerformEMVTransactionSaveCard(true))
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading1(false);
        });
    }
  };

  const handlePerformEMVTransactionRefund = async () => {
    setPerformEMVTransactionLoading3(true);
    if (enablePerformEMVTxn && enableRefundCard) {
      data = {
        hotelId: hotelId,
        bookingID: bookingId,
        reservation_id: selectedResId,
        cust_email: folioSummary?.invoice?.customer_email,
        name: folioSummary?.invoice?.customer_name,
        is_emv: "YES",
        trans_type: "refund",
        add_card: performEMVTransactionSaveCard,
        amount: Number(performEMVTransactionAmt),
        logged_user_email: loggedUserEmail,
      };
      if (emvTerminalId) {
        data["terminal_id"] = emvTerminalId;
      }
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/savedCardStandaloneRefund/",
        data
      )
        .then((res) => updateSummaryDataFromCache())
        .then((res) => updateInvoice())
        .then((res) => callInvoiceIdGeneration())
        .then((res) => {
          toast.success(`${t("EMV Card refunded successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => setPerformEMVTransactionLoading3(false))
        .then((res) => handleClosePerformEMVTransaction())
        .then((res) => setPerformEMVTransactionAmt())
        .then((res) => setPerformEMVTransactionSaveCard(true))
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading3(false);
        });
    }
  };

  const handlePerformEMVTransactionHold = () => {
    setPerformEMVTransactionLoading2(true);
    data = {
      hotelId: hotelId,
      bookingID: bookingId,
      reservation_id: selectedResId,
      cust_email: folioSummary?.invoice?.customer_email,
      name: folioSummary?.invoice?.customer_name,
      is_emv: "YES",
      trans_type: "auth",
      add_card: performEMVTransactionSaveCard,
      amount: Number(performEMVTransactionAmt),
      logged_user_email: loggedUserEmail,
    };
    if (emvTerminalId) {
      data["terminal_id"] = emvTerminalId;
    }
    if (custom_pg_name === "SHIFT4" && shift4_utg_url) {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/generateShift4UtgRequest/",
        data
      )
        .then((shift4_utg_request) => {
          console.log(shift4_utg_request);
          let URL = shift4_utg_url + "api/rest/v1/transactions/authorization";
          if (emvTerminalId) {
            data["terminal_id"] = emvTerminalId;
            let shift4_terminal_info = getShift4TerminalDetails(emvTerminalId);
            URL =
              shift4_terminal_info.shift4_utg_url +
              "api/rest/v1/transactions/authorization";
            shift4_utg_request["device"]["terminalId"] =
              shift4_terminal_info.shift4_api_terminal_id;
          }

          let myHeaders = new Headers();
          myHeaders.append("InterfaceVersion", "4.0");
          myHeaders.append("InterfaceName", "Stayflexi");
          myHeaders.append("CompanyName", "Stayflexi");
          myHeaders.append("AccessToken", shift4_access_token);
          myHeaders.append("Content-Type", "application/json");
          let raw = JSON.stringify(shift4_utg_request);
          let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(URL, requestOptions)
            .then((response) => {
              return response.json().then((json) => {
                return response.ok
                  ? json
                  : Promise.reject({ status: response.status, json });
              });
            })
            .then((result) => {
              console.log(result);
              data["shift4_utg_response"] = result;
              data["shift4_utg_request"] = shift4_utg_request;
              djangoHandleDataMutationRequest(
                "POST",
                "invoice/recordShift4UtgResponse/",
                data
              )
                .then((res) => updateSummaryDataFromCache())
                .then((res) => updateInvoice())
                .then((res) => callInvoiceIdGeneration())
                .then((res) => {
                  console.log(res);
                  toast.success(`${t("EMV Card authorized successfully")}`, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                })
                .then((res) => setPerformEMVTransactionLoading2(false))
                .then((res) => handleClosePerformEMVTransaction())
                .then((res) => setPerformEMVTransactionAmt())
                .then((res) => setPerformEMVTransactionSaveCard(true))
                .catch((error) => {
                  toast.error(error, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  });
                  setPerformEMVTransactionLoading2(false);
                });
            })
            .catch((error) => {
              if (
                error.json &&
                error.json.result &&
                error.json.result.length > 0 &&
                error.json.result[0].error
              ) {
                console.log(error);
                console.log(error.json.result[0].error.longText);
                console.error(error.json);
                toast.error(
                  "Transaction failed. Gateway message: " +
                    error.json.result[0].error.longText,
                  {
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 8000,
                    hideProgressBar: true,
                    bodyClassName: "importantToast",
                  }
                );
                setPerformEMVTransactionLoading2(false);
              } else {
                console.log(error);
                toast.error("Failed to authorize the card", {
                  closeOnClick: true,
                  pauseOnHover: true,
                  autoClose: 8000,
                  hideProgressBar: true,
                  bodyClassName: "importantToast",
                });
              }
            });
        })
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading2(false);
        });
    } else {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/authorizeCustomerCardOwn/",
        data
      )
        .then((res) => updateSummaryDataFromCache())
        .then((res) => updateInvoice())
        .then((res) => callInvoiceIdGeneration())
        .then((res) => {
          toast.success(`${t("EMV Card authorized successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => setPerformEMVTransactionLoading2(false))
        .then((res) => handleClosePerformEMVTransaction())
        .then((res) => setPerformEMVTransactionAmt())
        .then((res) => setPerformEMVTransactionSaveCard(true))
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setPerformEMVTransactionLoading2(false);
        });
    }
  };

  /* Settle dues --- Added cards transaction */

  // Added cards transaction modal

  const [openAddedCard, setOpenAddedCard] = useState(false);
  const [openedAddedCardData, setOpenedAddedCardData] = useState(false);
  const handleOpenAddedCard = (item) => {
    setOpenAddedCard(true);
    setOpenedAddedCardData(item);
  };
  const handleCloseAddedCard = () => setOpenAddedCard(false);

  const [CurrencyAMount, setCurrencyAMount] = useState(summaryData.balance_due);
  const [selectedCurrency, setselectedCurrency] = useState("USD");
  const [fetchCurrentRate, setfetchCurrentRate] = useState(0);

  const fetchRates = async () => {
    // handleSpringDataRequest(
    //   `core/api/v1/reservation/get-currency-value?from=${hotelCurrency}&to=${selectedCurrency}&amount=${parseFloat(
    //     CurrencyAMount
    //   )}`
    // )
    //   .then((res) => setfetchCurrentRate(res.message.toFixed(2)))
    //   .catch((err) => console.error(err));

    const data = await axios.get(
      `${process.env.REACT_APP_SPRING_API_URL}web-api/currency-convertion-value?from=${hotelCurrency}&to=${selectedCurrency}&value=${CurrencyAMount}`
    );
    // var requestURL = `https://api.exchangerate.host/latest?base=${hotelCurrency}&amount=${CurrencyAMount}&symbols=${selectedCurrency}`;
    // var request = new XMLHttpRequest();
    // request.open("GET", requestURL);
    // request.responseType = "json";
    // request.send();

    // request.onload = function () {
    //   var response = request.response;
    setfetchCurrentRate(data.data.toFixed(2));
    // };
  };

  /* Settle dues ---  ota saved  cards transaction */

  //  ota saved  cards transaction modal

  const [openOTAOTAAddedCard, setOpenOTAAddedCard] = useState(false);
  const handleOpenOTAAddedCard = () => setOpenOTAAddedCard(true);
  const handleCloseOTAAddedCard = () => setOpenOTAAddedCard(false);

  // Handling tax breakdown coming into the summary data
  // const [taxBreakdown, setTaxBreakdown] = useState([])
  // const getTaxBreakdown = () => {
  //   typeof summaryData?.tax_breakdown === "object" ?
  //   setTaxBreakdown(summaryData?.tax_breakdown)
  //   :
  //   setTaxBreakdown(JSON.parse(summaryData?.tax_breakdown))
  // }

  // useEffect(() => {
  //   getTaxBreakdown()
  // }, [summaryData])

  const handleGetBillToCta = () => {
    let adjustedAmt = 0;
    let finalAmt = 0;
    if (folioSummary?.invoice?.cta_billed_items.length > 0) {
      const data = folioSummary?.invoice.cta_billed_items?.forEach(
        (item) => (adjustedAmt += item?.refund_amount)
      );
      finalAmt = Number(summaryData?.bill_to_cta_amt) - Number(adjustedAmt);
    } else {
      finalAmt = 0;
    }
    return parseFloat(finalAmt).toFixed(2);
  };

  const handleTotalRefund = () => {
    let refund = 0;
    let adjustedAmt = 0;
    const data = folioSummary?.invoice?.cta_billed_items?.forEach(
      (item) => (adjustedAmt += item?.refund_amount)
    );
    if (folioSummary?.invoice?.cta_billed_items.length > 0) {
      refund = Number(summaryData?.refund) - Number(adjustedAmt);
    } else {
      refund =
        Number(summaryData?.refund) - Number(summaryData?.bill_to_cta_amt);
    }
    return Number(refund).toFixed(2);
  };
  const [selfCheckins, setselfCheckins] = useState([]);
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/reservation/get-selfservice-details-folio/?bookingsId=${bookingId}`
    )
      .then((res) => {
        setselfCheckins(res?.details[0]?.selfServiceType);
      })
      .catch((err) =>
        toast.error(
          `${t("Error in getting selfCheckins! Please try again later.")}`
        )
      );
  }, []);

  // Confirmation modal to remove the promocode
  const [openConfirmRemovePromo, setOpenConfirmRemovePromo] = useState(false);
  const handleOpenConfirmRemovePromo = () => setOpenConfirmRemovePromo(true);
  const handleCloseConfirmRemovePromo = () => setOpenConfirmRemovePromo(false);

  // Handle remove promocode
  // Remove promocode loading
  const [removePromoLoading, setRemovePromoLoading] = useState(false);

  const handleRemovePromocode = () => {
    const amt = folioSummary?.invoice?.items?.filter(
      (e) => e.service_amount_desc === "Promo discount"
    );
    setRemovePromoLoading(true);
    handleSpringDataRequest(
      `core/api/v1/reservation/remove-applied-promo-code?bookingId=${bookingId}&serviceId=${amt[0]?.service_id}`
    )
      .then((res) => toast.success(`${t("Promocode removed successfully!")}`))
      .then((res) => updateInvoice())
      .then((res) => updateSummaryData())
      .then((res) => handleCloseConfirmRemovePromo())
      .then((res) => setRemovePromoLoading(false))

      .catch((err) => setRemovePromoLoading(false))
      .catch((err) => handleCloseConfirmRemovePromo())
      .catch((err) => toast.error(`${t("Error in removing the promocode.")}`));
  };

  const [roomDirtyStatus, setroomDirtyStatus] = useState([]);
  const [isDirtyRooms, setisDirtyRooms] = useState(false);

  useEffect(() => {
    setCheckinLoading(true);
    folioSummary &&
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/reservation/get-room-dirty-status`,
        folioSummary?.invoice?.room_ids_list
      )
        .then((res) => {
          setroomDirtyStatus(Object?.entries(res));
          const data = Object.values(res).filter((e) => e === "DIRTY");
          setisDirtyRooms(data?.length > 0 ? true : false);
          setCheckinLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setCheckinLoading(false);
        });
  }, [folioSummary]);

  // Get the tax percentage
  function getTaxPercent(taxVal) {
    let feeBreakdown = 0.0;
    if (Object.entries(summaryData?.fee_breakdown)?.length > 0) {
      Object.entries(summaryData?.fee_breakdown).map((fee) => {
        feeBreakdown = feeBreakdown + fee[1];
      });
    }
    // const feeBreakdown =
    //   Object.entries(summaryData?.fee_breakdown).length > 0
    //     ? Number(Object.entries(summaryData?.fee_breakdown)[0][1].toFixed(2))
    //     : 0;
    let updatedServiceAmt =
      Number(summaryData.total_pretax_amount_with_services) -
      Number(feeBreakdown);
    let taxPercent = 0;
    if (updatedServiceAmt !== 0) {
      taxPercent = Number(taxVal) / updatedServiceAmt;
      taxPercent = taxPercent * 100;
    } else {
      taxPercent = 0;
    }
    return Number(taxPercent).toFixed(2);
  }

  // Render the tax breakdown
  function renderTaxBreakdown() {
    const taxes = { ...summaryData?.tax_breakdown };
    let showGST = true;
    if (taxes.hasOwnProperty("GST")) {
      if (Object.keys(taxes).length > 1 && Number(taxes?.GST) === 0) {
        delete taxes?.GST;
        showGST = false;
      }
    }
    return { taxes: taxes, showGST: showGST };
  }

  const customerState =
    folioSummary?.invoice?.customer_address?.customer_address_state.toUpperCase();
  const propertyState = data?.accessControl?.hotel?.hotel_state.toUpperCase();
  // console.log(customerState);

  const checkCheckinImages = () => {
    if (
      data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
      data?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
      JSON.parse(data?.accessControl?.user_feature_map?.UploadIdToCheckin)
        .read !== "YES"
    ) {
      handleCheckin();
    } else {
      handleSpringDataRequest(
        `core/api/v1/image/get-user-id-images?emailId=${folioSummary?.invoice?.customer_email}`
      )
        .then((res) => {
          if (
            res.frontUrl === null ||
            res.frontUrl === undefined ||
            res.frontUrl === "" ||
            res.backUrl === "" ||
            res.backUrl === null ||
            res.backUrl === undefined
          ) {
            toast.warning(`${t("Please add user id images for checkin")}`);
          } else {
            handleCheckin();
          }
        })
        .catch((err) => toast.error(`${t("Something went wrong")}`));
    }
  };

  //STRIPE ONLINE PAYMENT INTENT CREATION
  const [stripeAccount, setStripeAccount] = useState(
    data?.accessControl?.hotel?.pg_account_id
  );
  const magicLinkMap = magicLinkBreakdownMap;
  const [clientSecretKey, setClientSecretKey] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [onlinePaymentAmount, setOnlinePaymentAmount] = useState(0);
  const [performPayment, setPerformPayment] = useState(false);
  const [onlinePaymentLoader, setOnlinePaymentLoader] = useState(false);
  const [performOnlinePaymentModal, setPerformOnlinePaymentModal] =
    useState(false);
  const openPerformOnlinePaymentModal = () =>
    setPerformOnlinePaymentModal(true);
  const closePerformOnlinePaymentModal = () =>
    setPerformOnlinePaymentModal(false);

  const [paymentIntentModal, setPaymentIntentModal] = useState(false);
  const openPaymentIntentModal = () => setPaymentIntentModal(true);
  const closePaymentIntentModal = () => setPaymentIntentModal(false);

  const handleCreateOnlinePaymentIntent = () => {
    if (onlinePaymentAmount <= 0) {
      toast.error(`${t("Payment amount must be greater than 0")}`);
      return;
    }
    setOnlinePaymentLoader(true);
    let request_payload = {
      hotel_id: `${hotelId}`,
      amount: Number(onlinePaymentAmount).toFixed(2),
      hotelName: folioSummary.hotel.hotel_name,
      currency: hotelCurrency,
      booking_id: bookingId,
      module_source: "BOOKING_FOLIO",
      booking_source: "BOOKING_FOLIO",
      stripe_account: stripeAccount,
    };
    djangoHandleDataMutationRequest(
      "POST",
      "payments/createDirectPaymentIntent/",
      request_payload
    )
      .then((res) => {
        if (res?.client_secret_key !== "") {
          setClientSecretKey(res?.client_secret_key);
          setPaymentIntentId(res?.id);
        }
      })
      .then((res) => setOnlinePaymentLoader(false))
      .then((res) => openPaymentIntentModal());
  };

  const handleStripeSuccessPayment = (paymentId, bookingId) => {
    setOnlinePaymentLoader(true);
    let request_payload = {
      hotelId: hotelId,
      booking_id: bookingId,
      payment_gateway_id: paymentId,
      pg_name: "STRIPE",
      module_source: "BOOKING_FOLIO",
      booking_source: "BOOKING_FOLIO",
      amount: Number(onlinePaymentAmount).toFixed(2),
      requires_post_payment_confirmation: "true",
      customer_email: folioSummary?.invoice?.customer_email,
      stripe_account: stripeAccount,
      logged_user_email: loggedUserEmail,
    };
    djangoHandleDataMutationRequest(
      "POST",
      "payments/recordPayment/",
      request_payload
    )
      .then((res) =>
        toast.success(`${t("Online payment successfully recorded!")}`)
      )
      .then((res) => updateInvoice())
      .then((res) => updateSummaryData())
      .then((res) => setOnlinePaymentLoader(false))
      .then((res) => setOnlinePaymentAmount(0))
      .then((res) => closePaymentIntentModal())
      .then((res) => closePerformOnlinePaymentModal());
  };

  const [showCheckinButton, setshowCheckinButton] = useState(false);

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/get-night-audit-flag?currentDate=${moment(
        folioSummary.invoice.actual_checkin.replace("T", " "),
        "YYYY-MM-DD HH:mm:ss"
      )
        .subtract(1, "days")
        .format("DD-MM-YYYY")}&opType=WALK_IN`
    )
      .then((res) => {
        if (
          data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
          data?.accessControl?.ADMINRIGHTS === "SUBADMIN"
        ) {
          setshowCheckinButton(true);
        } else {
          setshowCheckinButton(res);
        }
      })
      .catch((err) => console.log(err));
  }, [folioSummary.invoice.actual_checkin]);

  const [hideCheckinForUnassigned, sethideCheckinForUnassigned] =
    useState(false);

  useEffect(() => {
    if (hotelType !== "ISHA") {
      sethideCheckinForUnassigned(
        folioSummary?.invoice?.confirmed_room_ids_list?.includes("UNASSIGNED")
      );
    }
  }, [folioSummary]);

  /* Settle dues --- RazorpayPOS transaction */

  // RazorpayPOS transaction modal

  const [
    openPerformRazorpayPOSTransaction,
    setOpenPerformRazorpayPOSTransaction,
  ] = useState(false);
  const [p2pRequestId, setP2pRequestId] = useState("");
  const [posPaymentAmount, setPosPaymentAmount] = useState("");
  const [posPaymentMode, setPosPaymentMode] = useState("");
  const [initiatePaymetLoader, setInitiatePaymentLoader] = useState(false);
  const [paymentStatusLoader, setPaymentStatusLoader] = useState(false);
  const [abortPaymentLoader, setAbortPaymentLoader] = useState(false);

  const initiatePayment = () => {
    setInitiatePaymentLoader(true);
    const payload = {
      hotelId: hotelId,
      bookingId: bookingId,
      amount: posPaymentAmount,
      mode: posPaymentMode,
      mobileNumber: folioSummary?.invoice?.customer_phone,
      email: folioSummary?.invoice?.customer_email,
    };

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/pg/initiate-razorpay-pos-payment`,
      payload
    )
      .then((data) => {
        setInitiatePaymentLoader(false);
        if (data?.code === "200") {
          setP2pRequestId(data?.message);
          toast.success("Payment initiated");
        } else {
          toast.success(data?.message);
        }
      })
      .catch(() => {
        setInitiatePaymentLoader(false);
        toast.error("Something went wrong");
      });
  };

  const getPaymentStatus = () => {
    setPaymentStatusLoader(true);
    handleSpringDataRequest(
      `core/api/v1/pg/get-razorpay-pos-payment-status?bookingId=${bookingId}&p2pRequestId=${p2pRequestId}&userName=NA`
    )
      .then((status) => {
        setPaymentStatusLoader(false);
        toast.success(status?.message);
        if (status?.code === "200") {
          updateInvoice();
          updateSummaryData();
          setOpenPerformRazorpayPOSTransaction(false);
        }
      })
      .catch(() => {
        setPaymentStatusLoader(false);
        toast.error("Something went wrong");
      });
  };
  const cancelPayment = () => {
    setAbortPaymentLoader(true);
    handleSpringDataRequest(
      `core/api/v1/pg/cancel-razorpay-pos-payment?bookingId=${bookingId}&p2pRequestId=${p2pRequestId}`
    )
      .then(() => {
        setAbortPaymentLoader(false);
        toast.success("Payment aborted successfully");
        setOpenPerformRazorpayPOSTransaction(false);
      })
      .catch(() => {
        setAbortPaymentLoader(false);
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <div className="invoiceSummary">
        <div className="summaryHeader">{t("Folio summary")}</div>
        <div className="invoiceContent">
          <div className="feeBreakdownWrapper">
            <div className="feeBreakdownHeader">{t("Booking Amount Breakdown")}</div>
            <div className="feeBreakdown">
              <div className="breakdownHeader">{t("Total without taxes")}</div>
              <div className="breakdownVal">
                {hotelCurrency === hotelConvertedCurrency
                  ? currency
                  : hotelConvertedCurrencySymbol}{" "}
                {hotelCurrency === hotelConvertedCurrency
                  ? parseFloat(
                      // folioSummary.invoice.invoice_subtotal_amount
                      summaryData.total_pretax_amount_with_services
                    ).toFixed(2)
                  : parseFloat(
                      // folioSummary.invoice.invoice_subtotal_amount
                      summaryData.total_pretax_amount_with_services *
                        convertedAmount
                    ).toFixed(2)}
              </div>
            </div>
            <div className="feeBreakdown">
              <div className="breakdownHeader">{t("Total tax amount")}</div>
              <div className="breakdownVal">
                {hotelCurrency === hotelConvertedCurrency
                  ? currency
                  : hotelConvertedCurrencySymbol}{" "}
                {summaryData && hotelCurrency === hotelConvertedCurrency
                  ? parseFloat(
                      // folioSummary.invoice.invoice_total_amount
                      summaryData?.invoice_total_tax_amount
                    ).toFixed(2)
                  : parseFloat(
                      // folioSummary.invoice.invoice_total_amount
                      summaryData?.invoice_total_tax_amount * convertedAmount
                    ).toFixed(2)}
              </div>
            </div>
            <div className="feeBreakdown">
              <div className="breakdownHeader">
                {t("Total with taxes and fees")}
              </div>
              <div className="breakdownVal">
                {hotelCurrency === hotelConvertedCurrency
                  ? currency
                  : hotelConvertedCurrencySymbol}{" "}
                {summaryData && hotelCurrency === hotelConvertedCurrency
                  ? parseFloat(
                      // folioSummary.invoice.invoice_total_amount
                      summaryData?.total_amount_with_services
                    ).toFixed(2)
                  : parseFloat(
                      // folioSummary.invoice.invoice_total_amount
                      summaryData?.total_amount_with_services * convertedAmount
                    ).toFixed(2)}
              </div>
            </div>
            {/* taxes breakdown */}
            <div className="separator" />
            {Object.entries(summaryData?.fee_breakdown).length > 0 && (
              <>
                {" "}
                <div className="feeBreakdownHeader">
                  {t("Booking fees breakdown")}
                </div>
                <div className="feeBreakdown">
                  {Object.entries(summaryData?.fee_breakdown).map((fee) => {
                    return (
                      <>
                        <div className="breakdownHeader">{fee[0]}</div>
                        <div className="breakdownVal">
                          {hotelCurrency === hotelConvertedCurrency
                            ? currency
                            : hotelConvertedCurrencySymbol}{" "}
                          {hotelCurrency === hotelConvertedCurrency
                            ? parseFloat(fee[1]).toFixed(2)
                            : parseFloat(fee[1] * convertedAmount).toFixed(2)}
                        </div>
                      </>
                    );
                  })}
                  {/* <div className="breakdownHeader">
                    {Object.entries(summaryData?.fee_breakdown)[0][0]}
                  </div>
                  <div className="breakdownVal">
                    {hotelCurrency === hotelConvertedCurrency
                      ? currency
                      : hotelConvertedCurrencySymbol}{" "}
                    {hotelCurrency === hotelConvertedCurrency
                      ? parseFloat(
                          Object.entries(summaryData?.fee_breakdown)[0][1]
                        ).toFixed(2)
                      : parseFloat(
                          Object.entries(summaryData?.fee_breakdown)[0][1] *
                            convertedAmount
                        ).toFixed(2)}
                  </div> */}
                </div>
                <div className="separator" />
              </>
            )}
            {["-1", "-2", "-3"].includes(checked) && (
              <div className="taxesBreakdownWrapper">
                <div className="taxBreakdownHeader">
                  {t("Room Taxes Breakdown")}
                </div>
                {(currency === "INR" || currency === "Rs.") &&
                ((igst?.status === "ENABLED" &&
                  customerState &&
                  customerState !== "NA" &&
                  customerState !== propertyState) ||
                  dbigst?.status === "ENABLED") ? (
                  <div>
                    <div className="taxBreakdown">
                      <div className="breakdowns">
                        <div className="breakdownHeader">{t("IGST")}</div>
                        <div className="breakdownVal">
                          {hotelCurrency === hotelConvertedCurrency
                            ? currency
                            : hotelConvertedCurrencySymbol}{" "}
                          {hotelCurrency === hotelConvertedCurrency
                            ? summaryData?.tax_breakdown?.GST
                              ? parseFloat(
                                  summaryData?.tax_breakdown.GST
                                ).toFixed(2)
                              : summaryData?.tax_breakdown.CGST === undefined ||
                                summaryData?.tax_breakdown.CGST === null
                              ? 0
                              : parseFloat(
                                  summaryData?.tax_breakdown.CGST * 2
                                ).toFixed(2)
                            : summaryData?.tax_breakdown.GST
                            ? parseFloat(
                                summaryData?.tax_breakdown.GST *
                                  2 *
                                  convertedAmount
                              ).toFixed(2)
                            : summaryData?.tax_breakdown.CGST === undefined ||
                              summaryData?.tax_breakdown.CGST === null
                            ? 0
                            : parseFloat(
                                summaryData?.tax_breakdown.CGST *
                                  2 *
                                  convertedAmount
                              ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {Object.keys(summaryData?.tax_breakdown)?.length > 0 ? (
                      <div className="taxBreakdown">
                        {Object.entries(renderTaxBreakdown()["taxes"]).map(
                          ([key, value]) => (
                            <div className="breakdowns">
                              <div className="breakdownHeader">
                                {key}
                                {/* ({getTaxPercent(value)} %) */}
                              </div>
                              <div className="breakdownVal">
                                {hotelCurrency === hotelConvertedCurrency
                                  ? currency
                                  : hotelConvertedCurrencySymbol}{" "}
                                {hotelCurrency === hotelConvertedCurrency
                                  ? parseFloat(value).toFixed(2)
                                  : parseFloat(value * convertedAmount).toFixed(
                                      2
                                    )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="taxBreakdown">
                        <div className="breakdowns">
                          <div className="breakdownHeader">
                            {folioSummary.hotel.hotel_country?.toUpperCase() ===
                            "INDIA"
                              ? "GST"
                              : `${t("Taxes and fees")}`}
                          </div>
                          <div className="breakdownVal">
                            {hotelCurrency === hotelConvertedCurrency
                              ? currency
                              : hotelConvertedCurrencySymbol}{" "}
                            {"0"}
                          </div>
                        </div>
                      </div>
                    )}
                    {folioSummary.hotel.hotel_country?.toUpperCase() ===
                      "INDIA" &&
                      summaryData?.tax_breakdown &&
                      renderTaxBreakdown()["showGST"] && (
                        <div className="feeBreakdown">
                          <div className="breakdownHeader">
                            {t("CGST")}
                            {/* (
                      {(
                        getTaxPercent(summaryData?.tax_breakdown.GST) / 2.0
                      ).toFixed(2)}{" "}
                      %) */}
                          </div>
                          <div className="breakdownVal">
                            {hotelCurrency === hotelConvertedCurrency
                              ? currency
                              : hotelConvertedCurrencySymbol}{" "}
                            {
                              // parseFloat(folioSummary.invoice.cgst).toFixed(2)
                              hotelCurrency === hotelConvertedCurrency
                                ? (
                                    parseFloat(summaryData?.tax_breakdown.GST) /
                                    2
                                  ).toFixed(2)
                                : (
                                    parseFloat(
                                      summaryData?.tax_breakdown.GST *
                                        convertedAmount
                                    ) / 2
                                  ).toFixed(2)
                            }
                          </div>
                        </div>
                      )}
                    {folioSummary.hotel.hotel_country?.toUpperCase() ===
                      "INDIA" &&
                      summaryData?.tax_breakdown &&
                      renderTaxBreakdown()["showGST"] && (
                        <div className="feeBreakdown">
                          <div className="breakdownHeader">
                            {unionTerritories?.includes(propertyState) &&
                            utgst?.status === "ENABLED"
                              ? "UTGST"
                              : "SGST"}
                            {/* (
                    {(
                      getTaxPercent(summaryData?.tax_breakdown.GST) / 2.0
                    ).toFixed(2)}{" "}
                    %) */}
                          </div>
                          <div className="breakdownVal">
                            {hotelCurrency === hotelConvertedCurrency
                              ? currency
                              : hotelConvertedCurrencySymbol}{" "}
                            {hotelCurrency === hotelConvertedCurrency
                              ? // parseFloat(folioSummary.invoice.cgst).toFixed(2)
                                (
                                  parseFloat(summaryData?.tax_breakdown.GST) / 2
                                ).toFixed(2)
                              : (
                                  (parseFloat(summaryData?.tax_breakdown.GST) /
                                    2) *
                                  convertedAmount
                                ).toFixed(2)}
                          </div>
                        </div>
                      )}
                  </div>
                )}

                {summaryData?.promo_tax !== 0 &&
                  summaryData?.promo_amount !== undefined &&
                  summaryData?.promo_amount !== null && (
                    <div className="feeBreakdown">
                      <div className="breakdownHeader">{t("PROMO TAX")}</div>
                      <div className="breakdownVal">
                        {hotelCurrency === hotelConvertedCurrency
                          ? currency
                          : hotelConvertedCurrencySymbol}{" "}
                        {hotelCurrency === hotelConvertedCurrency
                          ? parseFloat(summaryData?.promo_tax)
                          : (
                              parseFloat(summaryData?.promo_tax) *
                              convertedAmount
                            ).toFixed(2)}
                      </div>
                    </div>
                  )}

                {/* Getting the services breakdown */}

                <div className="separator"></div>

                <div className="feeBreakdown">
                  <div
                    className="breakdownHeader"
                    style={{
                      position: "relative",
                      flexWrap: "wrap",
                    }}
                  >
                    {t("Service taxes")}
                  </div>
                  <div className="breakdownVal">
                    {hotelCurrency === hotelConvertedCurrency
                      ? currency
                      : hotelConvertedCurrencySymbol}{" "}
                    {hotelCurrency === hotelConvertedCurrency
                      ? getTotalServiceTax()
                      : (getTotalServiceTax() * convertedAmount).toFixed(2)}
                  </div>
                </div>
              </div>
            )}

            {/* {folioSummary?.invoice.items?.filter(
              (item) =>
                String(item.service_id) !== "0" &&
                String(item?.service_amount_type) === "DEBIT"
            ).length > 0 && (
              <>
                <div className="separator" />
                <div className="taxesBreakdownWrapper">
                  <div className="taxBreakdownHeader">
                    Service Taxes Breakdown
                  </div>
                  {folioSummary?.invoice.items.map((item) => (
                    <div className="feeBreakdown">
                      <div className="breakdownHeader">
                        {item?.service_desc}
                      </div>
                      <div className="breakdownVal">
                        {currency} {item?.service_tax}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )} */}
            <div className="separator"></div>
            {/* payments made with types */}
            {summaryData?.payment_made > 0.0 && (
              <div>
                <div className="paymentBreakdownHeader">
                  {t("Payment Breakdown")}
                </div>

                {summaryData.cash_payment > 0.0 &&
                  JSON.parse(data?.accessControl?.user_feature_map?.CashPayment)
                    .read === "YES" && (
                    <div className="feeBreakdown">
                      <div className="breakdownHeader">
                        <Stack direction={"row"}>
                          <Typography
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            {t("Cash payment")}
                          </Typography>
                        </Stack>
                      </div>
                      <div className="breakdownVal">
                        {hotelCurrency === hotelConvertedCurrency
                          ? currency
                          : hotelConvertedCurrencySymbol}{" "}
                        {hotelCurrency === hotelConvertedCurrency
                          ? parseFloat(summaryData.cash_payment).toFixed(2)
                          : parseFloat(
                              summaryData.cash_payment * convertedAmount
                            ).toFixed(2)}
                      </div>
                    </div>
                  )}

                {summaryData?.offline_card_payment > 0.0 &&
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.OfflineCardPayment
                  ).read === "YES" && (
                    <div className="feeBreakdown">
                      <div className="breakdownHeader">
                        <Stack direction={"row"}>
                          <Typography
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            {t("Offline card payment")}
                          </Typography>
                        </Stack>
                      </div>
                      <div className="breakdownVal">
                        {hotelCurrency === hotelConvertedCurrency
                          ? currency
                          : hotelConvertedCurrencySymbol}{" "}
                        {hotelCurrency === hotelConvertedCurrency
                          ? parseFloat(
                              summaryData.offline_card_payment
                            ).toFixed(2)
                          : parseFloat(
                              summaryData.offline_card_payment * convertedAmount
                            ).toFixed(2)}
                      </div>
                    </div>
                  )}

                {summaryData?.offline_check_payment > 0.0 &&
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.OfflineChequePayment
                  ).read === "YES" && (
                    <div className="feeBreakdown">
                      <div className="breakdownHeader">
                        <Stack direction={"row"}>
                          <Typography
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            {t("Offline cheque payment")}
                          </Typography>
                        </Stack>
                      </div>
                      <div className="breakdownVal">
                        {hotelCurrency === hotelConvertedCurrency
                          ? currency
                          : hotelConvertedCurrencySymbol}{" "}
                        {hotelCurrency === hotelConvertedCurrency
                          ? parseFloat(
                              summaryData.offline_check_payment
                            ).toFixed(2)
                          : parseFloat(
                              summaryData.offline_check_payment *
                                convertedAmount
                            ).toFixed(2)}
                      </div>
                    </div>
                  )}

                {summaryData.upi_payment > 0.0 && currency === "Rs." && (
                  <div className="feeBreakdown">
                    <div className="breakdownHeader">
                      <Stack direction={"row"}>
                        <Typography
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          {t("UPI payment")}
                        </Typography>
                      </Stack>
                    </div>
                    <div className="breakdownVal">
                      {hotelCurrency === hotelConvertedCurrency
                        ? currency
                        : hotelConvertedCurrencySymbol}{" "}
                      {hotelCurrency === hotelConvertedCurrency
                        ? parseFloat(summaryData.upi_payment).toFixed(2)
                        : parseFloat(
                            summaryData.upi_payment * convertedAmount
                          ).toFixed(2)}
                    </div>
                  </div>
                )}

                {summaryData.bank_transfer_payment > 0.0 &&
                  currency === "Rs." && (
                    <div className="feeBreakdown">
                      <div className="breakdownHeader">
                        <Stack direction={"row"}>
                          <Typography
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            {t("Bank transfer")}
                          </Typography>
                        </Stack>
                      </div>
                      <div className="breakdownVal">
                        {hotelCurrency === hotelConvertedCurrency
                          ? currency
                          : hotelConvertedCurrencySymbol}{" "}
                        {hotelCurrency === hotelConvertedCurrency
                          ? parseFloat(
                              summaryData.bank_transfer_payment
                            ).toFixed(2)
                          : parseFloat(
                              summaryData.bank_transfer_payment *
                                convertedAmount
                            ).toFixed(2)}
                      </div>
                    </div>
                  )}

                {summaryData.other_payment > 0.0 &&
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.OtherPaymentModes
                  ).read === "YES" && (
                    <div className="feeBreakdown">
                      <div className="breakdownHeader">
                        <Stack direction={"row"}>
                          <Typography
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            {t("Other payments")}
                          </Typography>
                        </Stack>
                      </div>
                      <div className="breakdownVal">
                        {hotelCurrency === hotelConvertedCurrency
                          ? currency
                          : hotelConvertedCurrencySymbol}{" "}
                        {hotelCurrency === hotelConvertedCurrency
                          ? parseFloat(summaryData.other_payment).toFixed(2)
                          : parseFloat(
                              summaryData.other_payment * convertedAmount
                            ).toFixed(2)}
                      </div>
                    </div>
                  )}

                {summaryData?.total_online_payment > 0.0 && (
                  <div className="feeBreakdown">
                    <div className="breakdownHeader">
                      {t("Gateway payments")}
                    </div>
                    <div className="breakdownVal">
                      {hotelCurrency === hotelConvertedCurrency
                        ? currency
                        : hotelConvertedCurrencySymbol}{" "}
                      {hotelCurrency === hotelConvertedCurrency
                        ? parseFloat(summaryData.total_online_payment).toFixed(
                            2
                          )
                        : parseFloat(
                            summaryData.total_online_payment * convertedAmount
                          ).toFixed(2)}
                    </div>
                  </div>
                )}

                <div className="separator" />
              </div>
            )}
            {/* Self checkins*/}
            {selfCheckins?.length > 0 && (
              <>
                <div className="paymentBreakdownHeader">
                  {t("Magic Link Breakdown")}
                </div>
                {selfCheckins?.map((e) => (
                  <div className="feeBreakdown">
                    <div className="breakdownHeader">
                      {magicLinkMap[e.type]}
                    </div>
                    <div className="breakdownVal">
                      {hotelCurrency === hotelConvertedCurrency
                        ? currency
                        : hotelConvertedCurrencySymbol}{" "}
                      {hotelCurrency === hotelConvertedCurrency
                        ? e.amount.toFixed(2)
                        : (e.amount * convertedAmount).toFixed(2)}
                    </div>
                  </div>
                ))}
                <div className="separator"></div>
              </>
            )}
            {/* Promocodes applied */}
            {summaryData?.promo_amount !== 0 &&
              summaryData?.promo_amount !== undefined &&
              summaryData?.promo_amount !== null && (
                <>
                  <div className="paymentBreakdownHeader">
                    {t("Applied Coupon Code / Offer")}
                  </div>
                  <div className="feeBreakdown">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        width: "75%",
                        wordBreak: "break-all",
                      }}
                    >
                      <Tooltip title={"Remove"}>
                        <div
                          style={{
                            marginTop: -1,
                            marginBottom: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOpenConfirmRemovePromo()}
                        >
                          <MdIcons.MdRemoveCircle color="#ff6347" />
                        </div>
                      </Tooltip>
                      <div
                        className="breakdownHeader"
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "5px",
                        }}
                      >
                        {folioSummary?.invoice?.applied_promocode}
                      </div>
                    </div>
                    <div
                      className="breakdownVal"
                      style={{ position: "relative", width: "25%" }}
                    >
                      {hotelCurrency === hotelConvertedCurrency
                        ? Number(summaryData?.promo_amount).toFixed(2)
                        : (
                            Number(summaryData?.promo_amount) * convertedAmount
                          ).toFixed(2)}
                    </div>
                  </div>
                  <div className="separator"></div>
                </>
              )}
            {}
            {/* end summary */}
            <div className="feeBreakdown">
              <div className="breakdownHeader" style={{ fontWeight: "500" }}>
                {t("Payment made")}
              </div>
              <div className="breakdownVal">
                {hotelCurrency === hotelConvertedCurrency
                  ? currency
                  : hotelConvertedCurrencySymbol}{" "}
                {hotelCurrency === hotelConvertedCurrency
                  ? parseFloat(summaryData.payment_made).toFixed(2)
                  : parseFloat(
                      summaryData.payment_made * convertedAmount
                    ).toFixed(2)}
              </div>
            </div>
            {Number(handleGetBillToCta()) > 0 && (
              <div className="feeBreakdown">
                <div className="breakdownHeader" style={{ fontWeight: "500" }}>
                  {t("Billed to")} -{" "}
                  {folioSummary?.invoice.booking_source_displayname}
                </div>
                <div className="breakdownVal">
                  {hotelCurrency === hotelConvertedCurrency
                    ? currency
                    : hotelConvertedCurrencySymbol}{" "}
                  {hotelCurrency === hotelConvertedCurrency
                    ? handleGetBillToCta()
                    : handleGetBillToCta() * convertedAmount}
                </div>
              </div>
            )}
            {Number(handleTotalRefund()) > 0 && (
              <div className="feeBreakdown">
                <div className="breakdownHeader" style={{ fontWeight: "500" }}>
                  {t("Refund")}
                </div>
                <div className="breakdownVal">
                  {hotelCurrency === hotelConvertedCurrency
                    ? currency
                    : hotelConvertedCurrencySymbol}{" "}
                  {hotelCurrency === hotelConvertedCurrency
                    ? handleTotalRefund()
                    : handleTotalRefund() * convertedAmount}
                </div>
              </div>
            )}
            <div className="feeBreakdown">
              <div className="breakdownHeader" style={{ fontWeight: "500" }}>
                <div className="d-flex align-items-center">
                  <p className="mb-0">{t("Balance due")}</p>
                  {hotelType !== "ISHA" && (
                    <Tooltip title={t("Convert currency")}>
                      <CurrencyExchangeIcon
                        onClick={() => handleOpenCurrencyModal()}
                        sx={{
                          // "&:hover": {
                          //   backgroundColor: "#B5B5C3",
                          //   borderRadius: "50px",
                          // },
                          marginLeft: "1rem",
                          color: "#1BC5BD",
                          cursor: "pointer",
                        }}
                      ></CurrencyExchangeIcon>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="breakdownVal">
                {hotelCurrency === hotelConvertedCurrency
                  ? currency
                  : hotelConvertedCurrencySymbol}{" "}
                {hotelCurrency === hotelConvertedCurrency
                  ? Number(summaryData.balance_due).toFixed(2)
                  : (Number(summaryData.balance_due) * convertedAmount).toFixed(
                      2
                    )}
              </div>
            </div>
          </div>
        </div>
        {/* rendering the invoice buttons based on the booking status */}
        {["-1", "-2", "-3"].includes(checked) && (
          <div>
            {(folioSummary?.invoice.status === "CONFIRMED" ||
              folioSummary?.invoice.status === "APPROVED_ENQUIRY") &&
              hotelCurrency === hotelConvertedCurrency &&
              JSON.parse(data?.accessControl?.user_feature_map?.CheckIn)
                .read === "YES" && (
                // !hideCheckinForUnassigned && (
                <div className="invoiceRenderBtns">
                  {hotelCurrency === hotelConvertedCurrency && settleDuesComp()}
                  {!hideCheckinForUnassigned && showCheckinButton && (
                    <div className="bookingCheckin">
                      {checkinLoading ? (
                        <button className="bookStatusBtn">
                          <CircularProgress size="15px" />
                        </button>
                      ) : (
                        <button
                          className="bookStatusBtn"
                          onClick={() =>
                            isDirtyRooms
                              ? handleOpenDirtyMoal()
                              : checkCheckinImages()
                          }
                        >
                          {t("Check-in")}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            {folioSummary?.invoice.status === "ADMIN_CONFIRMED" &&
              hotelCurrency === hotelConvertedCurrency &&
              JSON.parse(data?.accessControl?.user_feature_map?.CheckOut)
                .read === "YES" && (
                <div className="invoiceRenderBtns">
                  {hotelCurrency === hotelConvertedCurrency && settleDuesComp()}
                  {!hideCheckinForUnassigned && (
                    <div className="bookingCheckin">
                      {checkoutLoading ? (
                        <button className="bookStatusBtn">
                          <CircularProgress size="15px" />
                        </button>
                      ) : (
                        <button
                          className="bookStatusBtn"
                          onClick={() => handleCheckoutModal()}
                        >
                          {t("Check-out")}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            {folioSummary?.invoice.status === "ON_HOLD" && (
              <div className="invoiceRenderBtns">
                {hotelCurrency === hotelConvertedCurrency && settleDuesComp()}
                <div className="bookingCheckin">
                  {checkinLoading ? (
                    <button className="bookStatusBtn">
                      <CircularProgress size="15px" />
                    </button>
                  ) : (
                    <button
                      className="bookStatusBtn"
                      onClick={() => handleOpenReleaseBooking()}
                    >
                      {t("Release booking")}
                    </button>
                  )}
                </div>
              </div>
            )}

            {isNightAuditOn === true &&
              subUser.toLowerCase() !== "subuser" &&
              folioSummary?.invoice.status === "CHECKED_OUT" && (
                <div className="invoiceRenderBtn">{settleDuesCompLg()}</div>
              )}

            {isNightAuditOn === false &&
              folioSummary?.invoice.status === "CHECKED_OUT" && (
                <div className="invoiceRenderBtn">{settleDuesCompLg()}</div>
              )}

            {folioSummary && folioSummary.invoice.status === "CANCELLED" && (
              <div className="invoiceRenderBtn">{settleDuesCompLg()}</div>
            )}
            {folioSummary && folioSummary.invoice.status === "NO_SHOW" && (
              <div className="invoiceRenderBtn">{settleDuesCompLg()}</div>
            )}
          </div>
        )}
        {/* confirmation modal for releasing the booking */}
        <Modal
          open={openReleaseBooking}
          onClose={handleCloseReleaseBooking}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="foliosConfirmationModal">
            <div className="folioConfirmationWrapper">
              <div className="folioConfContent">
                {t("Are you sure you want to release the booking ?")}
              </div>
              <div className="folioConfButtons">
                <button
                  className="cancel"
                  onClick={() => handleCloseReleaseBooking()}
                >
                  {t("Cancel")}
                </button>
                {releaseBookingLoader === false ? (
                  <button
                    className="approved"
                    onClick={() => handleReleaseBooking()}
                    style={{ width: "150px" }}
                  >
                    {t("Confirm")}
                  </button>
                ) : (
                  <button className="approved" style={{ width: "150px" }}>
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Modal>

        {/* Dirty Rooms modal*/}
        <Modal
          open={openDirtyModal}
          onClose={handleCloseDirtyModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="foliosConfirmationModal">
            <div className="folioConfirmationWrapper">
              <div className="folioConfContent">
                {t(
                  "Some of the rooms are dirty still you want to proceed for checkin?"
                )}
                <div
                  className="selectedRoomIdLabel"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  {t("Dirty room Ids:")}
                </div>
                <div
                  className="selectedRoomIds"
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  {roomDirtyStatus?.map(([item, value]) => (
                    <div style={{ marginLeft: "5px" }}>
                      {value === "DIRTY" && <Chip label={item} />}
                    </div>
                  ))}
                </div>
              </div>
              <div className="folioConfButtons">
                <button
                  className="cancel"
                  onClick={() => handleCloseDirtyModal()}
                >
                  {t("Cancel")}
                </button>
                {checkinLoading === false ? (
                  showCheckinButton &&
                  !hideCheckinForUnassigned && (
                    <button
                      className="approved"
                      onClick={() => {
                        if (
                          data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
                          data?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.UploadIdToCheckin
                          ).read !== "YES"
                        ) {
                          handleCheckin();
                        } else {
                          handleSpringDataRequest(
                            `core/api/v1/image/get-user-id-images?emailId=${folioSummary?.invoice?.customer_email}`
                          )
                            .then((res) => {
                              if (
                                res.frontUrl === null ||
                                res.frontUrl === undefined ||
                                res.frontUrl === "" ||
                                res.backUrl === "" ||
                                res.backUrl === null ||
                                res.backUrl === undefined
                              ) {
                                toast.warning(
                                  `${t(
                                    "Please add user id images for checkin"
                                  )}`
                                );
                              } else {
                                handleCheckin();
                              }
                            })
                            .catch((err) => toast.error("Something wrong"));
                        }
                      }}
                      style={{ width: "150px" }}
                    >
                      {t("Check-in")}
                    </button>
                  )
                ) : (
                  <button className="approved" style={{ width: "150px" }}>
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Modal>
        {/* Currency convertor modal */}
        <Modal
          open={openCurrencyModal}
          onClose={handleCloseCurrencyModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="foliosConfirmationModal">
            <div className="folioConfirmationWrapper">
              <div className="folioConfContent">
                <p> {t("Convert currency")}</p>
                <p className="text=muted mt-2">
                  {t("Kindly recheck the currency value from your side")}{" "}
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <TextField
                    id="outlined-name"
                    label={`${hotelCurrency}`}
                    name={`${hotelCurrency}`}
                    value={CurrencyAMount}
                    onChange={(e) => setCurrencyAMount(e.target.value)}
                    sx={{ width: "8rem" }}
                    type={"text"}
                    InputProps={{ inputProps: { min: 0 } }}
                  />

                  <FormControl
                    fullWidth
                    style={{ width: "10rem", marginRight: "2rem" }}
                  >
                    <InputLabel
                      style={{ width: "10rem", marginRight: "2rem" }}
                      id="demo-simple-select-label"
                    >
                      {t("Select currency")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setselectedCurrency(e.target.value)}
                      style={{
                        marginLeft: "0rem",
                        width: "10rem",
                        marginRight: "2rem",
                      }}
                      className="mr-4"
                      label={t("Select currency")}
                      value={selectedCurrency}
                    >
                      {Currencies?.map((hours) => (
                        <MenuItem value={hours.code}>{hours.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-name"
                    label={t("Amount")}
                    name="Amount"
                    value={fetchCurrentRate}
                    // onChange={(e) => setCurrencyAMount(e.target.value)}
                    sx={{ width: "8rem" }}
                    type={"text"}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </div>
                {/* <Button variant="custom-button" onClick={fetchRates}>
                  Fetch Currency rates
                </Button> */}
              </div>
              <div className="folioConfButtons">
                <button
                  className="cancelBtn"
                  onClick={() => handleCloseCurrencyModal()}
                >
                  {t("Cancel")}
                </button>
                <Button
                  className="submit"
                  variant="custom-button"
                  onClick={() => fetchRates()}
                >
                  {t("Fetch rates")}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
        {/* SETTLE DUES --- PAYMENT SETTLE MODALS */}
        {/* settle dues --- cash payment modal */}
        <Modal
          open={openCashPayment}
          onClose={handleCloseCashPayment}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Enter cash amount")}
                  <div className="roomIdHeader">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("Room Id")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleRoomIdChange(e.target.value)}
                        style={{ marginLeft: "0rem" }}
                        label={t("Room Id")}
                        value={selectedRoomId}
                      >
                        {roomIdMapping?.map((resItem, index) => {
                          return (
                            <MenuItem
                              value={resItem?.roomId}
                              key={resItem?.resId}
                            >
                              {resItem?.roomId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="close" onClick={() => handleCloseCashPayment()}>
                  X
                </div>
              </div>
              <div className="amountWrapper">
                {roomHasPosOrders && (
                  <div className="fieldWrapper">
                    <MultiPaymentModeSelect
                      currentPaymentSelectionMode={currentPaymentSelectionMode}
                      setCurrentPaymentSelectionMode={
                        setCurrentPaymentSelectionMode
                      }
                      selectedLanguage={selectedLanguage}
                    />
                  </div>
                )}
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Cash amount")}
                    name="amount"
                    // value={roomHasPosOrders ? settlementTypeAmount : amount}
                    value={settlementTypeAmount}
                    // onChange={(e) =>
                    //   /^[0-9.]*$/.test(e.target.value)
                    //     ? setAmount(e.target.value)
                    //     : setAmount(amount)
                    // }
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setSettlementTypeAmount(e.target.value)
                        : setSettlementTypeAmount(settlementTypeAmount)
                    }
                    disabled={
                      (roomHasPosOrders &&
                        ["All", "Pos"].includes(settlementType)) ||
                      JSON.parse(
                        data?.accessControl?.user_feature_map?.EditBookingPrice
                      ).read !== "YES" ||
                      Number(summaryData?.balance_due) <= 0
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    InputProps={{ inputProps: { min: 0 } }}
                    error={Number(amount) <= 0 ? true : false}
                    helperText={
                      Number(summaryData?.balance_due) <= 0 ? (
                        <Typography sx={{ color: "red", fontSize: "10px" }}>
                          {t(
                            "Payment is already made. You can collect the payment once the balance due is greater than 0"
                          )}
                        </Typography>
                      ) : (
                        Number(amount) <= 0 && (
                          <Typography sx={{ color: "red", fontSize: "10px" }}>
                            {t("Cash amount must be greater than 0")}
                          </Typography>
                        )
                      )
                    }
                  />
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Return to customer calculator")}
                    name="amount"
                    // value={
                    //   Number(amount) >= Number(summaryData?.balance_due)
                    //     ? (
                    //         Number(amount) - Number(summaryData?.balance_due)
                    //       ).toFixed(2)
                    //     : "Less amount"
                    // }
                    value={
                      Number(settlementTypeAmount) >=
                      Number(summaryData?.balance_due)
                        ? (
                            Number(settlementTypeAmount) -
                            Number(summaryData?.balance_due)
                          ).toFixed(2)
                        : `${t("Less amount")}`
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    InputProps={{ inputProps: { min: 0 } }}
                    disabled
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={t("Date")}
                      value={offlineCashDate}
                      onChange={(newValue) => {
                        setOfflineCashDate(newValue);
                      }}
                      name="offline_card_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Amount description")}
                    name="service_amount_desc"
                    value={serviceAmountDesc}
                    onChange={(e) => setServiceAmountDesc(e.target.value)}
                    sx={{ width: "100%" }}
                    error={
                      hotelType === "ISHA" &&
                      serviceAmountDesc?.trim().length === 0
                        ? true
                        : false
                    }
                    helperText={
                      hotelType === "ISHA" &&
                      serviceAmountDesc?.trim().length === 0 && (
                        <Typography sx={{ color: "red", fontSize: "8px" }}>
                          {t("Amount description is compulsory.")}
                        </Typography>
                      )
                    }
                  />
                </div>
              </div>
              <div className="settleDuesSubmitBtns">
                <button
                  className="cancelBtn"
                  onClick={() => handleCloseCashPayment()}
                >
                  {t("Cancel")}
                </button>
                {cashPayLoading === false ? (
                  <button
                    className="submit"
                    onClick={() => handleCashPayment()}
                    disabled={
                      handleCashPaymentValidations() ||
                      currentPaymentSelectionMode.length === 0
                    }
                  >
                    {t("Submit")}
                  </button>
                ) : (
                  <button className="submit">
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Modal>
        {/* settle dues --- offline card payment modal */}
        <Modal
          open={openOfflineCardPay}
          onClose={handleCloseOfflineCardPay}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Enter card amount")}
                  <div className="roomIdHeader">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("Room Id")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleRoomIdChange(e.target.value)}
                        style={{ marginLeft: "0rem" }}
                        label={t("Room Id")}
                        value={selectedRoomId}
                      >
                        {roomIdMapping?.map((resItem, index) => {
                          return (
                            <MenuItem
                              value={resItem?.roomId}
                              key={resItem?.resId}
                            >
                              {resItem?.roomId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  className="close"
                  onClick={() => handleCloseOfflineCardPay()}
                >
                  X
                </div>
              </div>
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  {roomHasPosOrders && (
                    <div style={{ marginBottom: "25px" }}>
                      <MultiPaymentModeSelect
                        currentPaymentSelectionMode={
                          currentPaymentSelectionMode
                        }
                        setCurrentPaymentSelectionMode={
                          setCurrentPaymentSelectionMode
                        }
                        selectedLanguage={selectedLanguage}
                      />
                    </div>
                  )}
                  <TextField
                    id="outlined-name"
                    label={t("Card amount")}
                    name="amount"
                    // value={offlineCardAmt}
                    value={settlementTypeAmount}
                    // onChange={(e) =>
                    //   /^[0-9.]*$/.test(e.target.value)
                    //     ? setOfflineCardAmt(e.target.value)
                    //     : setOfflineCardAmt(offlineCardAmt)
                    // }
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setSettlementTypeAmount(e.target.value)
                        : setSettlementTypeAmount(settlementTypeAmount)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    disabled={
                      (roomHasPosOrders &&
                        ["All", "Pos"].includes(settlementType)) ||
                      JSON.parse(
                        data?.accessControl?.user_feature_map?.EditBookingPrice
                      ).read !== "YES" ||
                      Number(summaryData?.balance_due) <= 0
                    }
                    helperText={
                      Number(summaryData?.balance_due) <= 0 && (
                        <Typography sx={{ color: "red", fontSize: "10px" }}>
                          {t(
                            "Payment is already made. You can collect the payment once the balance due is greater than 0"
                          )}
                        </Typography>
                      )
                    }
                  />
                  {/* <TextField
                      error
                      id="outlined-name"
                      label="Card amount"
                      name="amount"
                      value={offlineCardAmt}
                      onChange={(e) => (/^[0-9.]*$/.test(e.target.value) ? setOfflineCardAmt(e.target.value) : setOfflineCardAmt(offlineCardAmt))}
                      sx={{ width: "100%" }}
                      type={"text"}
                      helperText={"Please enter the positive value"}
                    /> */}
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={t("Date")}
                      value={offlineCardDate}
                      onChange={(newValue) => {
                        setOfflineCardDate(newValue);
                      }}
                      name="offline_card_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                {hotelType === "ISHA" && (
                  // <div className="fieldWrapper">
                  <FormControl style={{ marginTop: "10px" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {t("Terminal ID")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={terminalId}
                      onChange={(e) => setterminalId(e.target.value)}
                    >
                      <FormControlLabel
                        value="1341512A"
                        control={<Radio />}
                        label="1341512A"
                      />
                      <FormControlLabel
                        value="1452171A"
                        control={<Radio />}
                        label="1452171A"
                      />
                      <FormControlLabel
                        value="0100441A"
                        control={<Radio />}
                        label="0100441A"
                      />
                      <FormControlLabel
                        value="1452169A"
                        control={<Radio />}
                        label="1452169A"
                      />
                      <FormControlLabel
                        value="1341514A"
                        control={<Radio />}
                        label="1341514A"
                      />
                      <FormControlLabel
                        value="0100440A"
                        control={<Radio />}
                        label="0100440A"
                      />
                      <FormControlLabel
                        value="1341510A"
                        control={<Radio />}
                        label="1341510A"
                      />
                      <FormControlLabel
                        value="63065257"
                        control={<Radio />}
                        label="63065257"
                      />
                      <FormControlLabel
                        value="63065258"
                        control={<Radio />}
                        label="63065258"
                      />
                      <FormControlLabel
                        value="63065259"
                        control={<Radio />}
                        label="63065259"
                      />
                      <FormControlLabel
                        value="64041058"
                        control={<Radio />}
                        label="64041058"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
                {hotelType === "ISHA" && (
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Approval code")}
                      name="cc_approval_code"
                      value={approvalCode}
                      onChange={(e) => setapprovalCode(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                )}
                {/* {data?.accessControl?.accessUser?.accessmap
                  ?.EnquiryDashboard && (
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label="Card holder name"
                      name="card_holder_name"
                      disabled={
                        data?.accessControl?.accessUser?.accessmap
                          ?.EnquiryDashboard === true
                          ? true
                          : false
                      }
                      value={cardHolder}
                      onChange={(e) => setcardHolder(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                )} */}
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Amount description")}
                    name="service_amount_desc"
                    value={offlineCardDesc}
                    onChange={(e) => setOfflineCardDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="settleDuesSubmitBtns">
                <button
                  className="cancelBtn"
                  onClick={() => handleCloseOfflineCardPay()}
                >
                  {t("Cancel")}
                </button>
                {offlineCardPayLoading === false ? (
                  <>
                    {JSON.parse(
                      data?.accessControl?.user_feature_map?.EnquiryDashboard
                    ).read === "YES" ? (
                      <button
                        className="submit"
                        onClick={() => handleOfflineCardPayment()}
                        disabled={
                          currentPaymentSelectionMode.length === 0 ||
                          (approvalCode !== ""
                            ? terminalId !== ""
                              ? offlineCardAmt > 0
                                ? false
                                : true
                              : true
                            : true)
                        }
                      >
                        {t("Submit")}
                      </button>
                    ) : (
                      <button
                        className="submit"
                        onClick={() => handleOfflineCardPayment()}
                        disabled={
                          (offlineCardAmt > 0 ? false : true) ||
                          currentPaymentSelectionMode.length === 0
                        }
                      >
                        {t("Submit")}
                      </button>
                    )}
                  </>
                ) : (
                  <button className="submit">
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Modal>
        {/* settle dues --- offline cheque payment modal */}
        <Modal
          open={openOfflineCheckPay}
          onClose={handleCloseOfflineCheckPay}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Enter cheque amount")}
                  <div className="roomIdHeader">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("Room Id")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleRoomIdChange(e.target.value)}
                        style={{ marginLeft: "0rem" }}
                        label={t("Room Id")}
                        value={selectedRoomId}
                      >
                        {roomIdMapping?.map((resItem, index) => {
                          return (
                            <MenuItem
                              value={resItem?.roomId}
                              key={resItem?.resId}
                            >
                              {resItem?.roomId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  className="close"
                  onClick={() => handleCloseOfflineCheckPay()}
                >
                  X
                </div>
              </div>
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  {roomHasPosOrders && (
                    <div style={{ marginBottom: "25px" }}>
                      <MultiPaymentModeSelect
                        currentPaymentSelectionMode={
                          currentPaymentSelectionMode
                        }
                        setCurrentPaymentSelectionMode={
                          setCurrentPaymentSelectionMode
                        }
                        selectedLanguage={selectedLanguage}
                      />
                    </div>
                  )}
                  <TextField
                    id="outlined-name"
                    label={t("Cheque amount")}
                    name="amount"
                    // value={offlineCheckAmt}
                    value={settlementTypeAmount}
                    // onChange={(e) =>
                    //   /^[0-9.]*$/.test(e.target.value)
                    //     ? setOfflineCheckAmt(e.target.value)
                    //     : setOfflineCheckAmt(offlineCheckAmt)
                    // }
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setSettlementTypeAmount(e.target.value)
                        : setSettlementTypeAmount(settlementTypeAmount)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    disabled={
                      (roomHasPosOrders &&
                        ["All", "Pos"].includes(settlementType)) ||
                      Number(summaryData?.balance_due) <= 0
                    }
                    helperText={
                      Number(summaryData?.balance_due) <= 0 && (
                        <Typography sx={{ color: "red", fontSize: "10px" }}>
                          {t(
                            "Payment is already made. You can collect the payment once the balance due is greater than 0"
                          )}
                        </Typography>
                      )
                    }
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={t("Date")}
                      value={offlineCheckDate}
                      onChange={(newValue) => {
                        setOfflineCheckDate(newValue);
                      }}
                      name="offline_check_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Description")}
                    name="description"
                    value={chequeDesc}
                    onChange={(e) => setChequeDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="settleDuesSubmitBtns">
                <button
                  className="cancelBtn"
                  onClick={() => handleCloseOfflineCheckPay()}
                >
                  {t("Cancel")}
                </button>
                {offlineCheckPayLoading === false ? (
                  <button
                    className="submit"
                    onClick={() => handleOfflineCheckPayment()}
                    disabled={
                      (offlineCheckAmt > 0 ? false : true) ||
                      currentPaymentSelectionMode.length === 0
                    }
                  >
                    {t("Submit")}
                  </button>
                ) : (
                  <button className="submit">
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Modal>
        {/* settle dues --- record UPI payment modal */}
        <Modal
          open={openUpi}
          onClose={handleCloseUpiPay}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Enter UPI amount")}
                  <div className="roomIdHeader">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("Room Id")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleRoomIdChange(e.target.value)}
                        style={{ marginLeft: "0rem" }}
                        label={t("Room Id")}
                        value={selectedRoomId}
                      >
                        {roomIdMapping?.map((resItem, index) => {
                          return (
                            <MenuItem
                              value={resItem?.roomId}
                              key={resItem?.resId}
                            >
                              {resItem?.roomId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="close" onClick={() => handleCloseUpiPay()}>
                  X
                </div>
              </div>
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  {roomHasPosOrders && (
                    <div style={{ marginBottom: "25px" }}>
                      <MultiPaymentModeSelect
                        currentPaymentSelectionMode={
                          currentPaymentSelectionMode
                        }
                        setCurrentPaymentSelectionMode={
                          setCurrentPaymentSelectionMode
                        }
                        selectedLanguage={selectedLanguage}
                      />
                    </div>
                  )}
                  <TextField
                    id="outlined-name"
                    label={t("UPI amount")}
                    name="amount"
                    // value={upiAmt}
                    value={settlementTypeAmount}
                    // onChange={(e) =>
                    //   /^[0-9.]*$/.test(e.target.value)
                    //     ? setUpiAmt(e.target.value)
                    //     : setUpiAmt(upiAmt)
                    // }
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setSettlementTypeAmount(e.target.value)
                        : setSettlementTypeAmount(settlementTypeAmount)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    helperText={
                      Number(summaryData?.balance_due) <= 0 && (
                        <Typography sx={{ color: "red", fontSize: "10px" }}>
                          {t(
                            "Payment is already made. You can collect the payment once the balance due is greater than 0"
                          )}
                        </Typography>
                      )
                    }
                    disabled={
                      (roomHasPosOrders &&
                        ["All", "Pos"].includes(settlementType)) ||
                      Number(summaryData?.balance_due) <= 0
                    }
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={t("Date")}
                      value={upiDate}
                      onChange={(newValue) => {
                        setUpiDate(newValue);
                      }}
                      name="upi_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Description")}
                    name="description"
                    value={upiDesc}
                    onChange={(e) => setUpiDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                {hotelType === "ISHA" && (
                  // <div className="fieldWrapper">
                  <FormControl style={{ marginTop: "10px" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {t("Terminal ID")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={terminalId}
                      onChange={(e) => setterminalId(e.target.value)}
                    >
                      <FormControlLabel
                        value="1341512A"
                        control={<Radio />}
                        label="1341512A"
                      />
                      <FormControlLabel
                        value="1452171A"
                        control={<Radio />}
                        label="1452171A"
                      />
                      <FormControlLabel
                        value="0100441A"
                        control={<Radio />}
                        label="0100441A"
                      />
                      <FormControlLabel
                        value="1452169A"
                        control={<Radio />}
                        label="1452169A"
                      />
                      <FormControlLabel
                        value="1341514A"
                        control={<Radio />}
                        label="1341514A"
                      />
                      <FormControlLabel
                        value="0100440A"
                        control={<Radio />}
                        label="0100440A"
                      />
                      <FormControlLabel
                        value="1341510A"
                        control={<Radio />}
                        label="1341510A"
                      />
                      <FormControlLabel
                        value="63065257"
                        control={<Radio />}
                        label="63065257"
                      />
                      <FormControlLabel
                        value="63065258"
                        control={<Radio />}
                        label="63065258"
                      />
                      <FormControlLabel
                        value="63065259"
                        control={<Radio />}
                        label="63065259"
                      />
                      <FormControlLabel
                        value="64041058"
                        control={<Radio />}
                        label="64041058"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
                {hotelType === "ISHA" && (
                  <div className="fieldWrapper">
                    <TextField
                      id="outlined-name"
                      label={t("Approval code")}
                      name="cc_approval_code"
                      value={approvalCode}
                      onChange={(e) => setapprovalCode(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </div>
                )}
              </div>
              <div className="settleDuesSubmitBtns">
                <button
                  className="cancelBtn"
                  onClick={() => handleCloseOfflineCheckPay()}
                >
                  {t("Cancel")}
                </button>
                {upiPayLoading === false ? (
                  hotelType === "ISHA" ? (
                    <button
                      className="submit"
                      onClick={() => handleUpiPayment()}
                      disabled={
                        currentPaymentSelectionMode.length === 0 ||
                        (approvalCode !== ""
                          ? terminalId !== ""
                            ? upiAmt > 0
                              ? false
                              : true
                            : true
                          : true)
                      }
                    >
                      {t("Submit")}
                    </button>
                  ) : (
                    <button
                      className="submit"
                      onClick={() => handleUpiPayment()}
                      disabled={
                        (upiAmt > 0 ? false : true) ||
                        currentPaymentSelectionMode.length === 0
                      }
                    >
                      {t("Submit")}
                    </button>
                  )
                ) : (
                  <button className="submit">
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Modal>

        {/* settle dues --- record UPI payment modal */}
        <Modal
          open={openBankTransferModePay}
          onClose={handleCloseBankTransferModePay}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Enter bank transfer amount")}
                  <div className="roomIdHeader">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("Room Id")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleRoomIdChange(e.target.value)}
                        style={{ marginLeft: "0rem" }}
                        label={t("Room Id")}
                        value={selectedRoomId}
                      >
                        {roomIdMapping?.map((resItem, index) => {
                          return (
                            <MenuItem
                              value={resItem?.roomId}
                              key={resItem?.resId}
                            >
                              {resItem?.roomId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  className="close"
                  onClick={() => handleCloseBankTransferModePay()}
                >
                  X
                </div>
              </div>
              <div className="amountWrapper">
                {roomHasPosOrders && (
                  <div className="fieldWrapper">
                    <MultiPaymentModeSelect
                      currentPaymentSelectionMode={currentPaymentSelectionMode}
                      setCurrentPaymentSelectionMode={
                        setCurrentPaymentSelectionMode
                      }
                      selectedLanguage={selectedLanguage}
                    />
                  </div>
                )}
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Amount")}
                    name="amount"
                    // value={bankTransferModeAmt}
                    value={settlementTypeAmount}
                    // onChange={(e) =>
                    //   /^[0-9.]*$/.test(e.target.value)
                    //     ? setBankTransferModeAmt(e.target.value)
                    //     : setBankTransferModeAmt(bankTransferModeAmt)
                    // }
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setSettlementTypeAmount(e.target.value)
                        : setSettlementTypeAmount(settlementTypeAmount)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    helperText={
                      Number(summaryData?.balance_due) <= 0 && (
                        <Typography sx={{ color: "red", fontSize: "10px" }}>
                          {t(
                            "Payment is already made. You can collect the payment once the balance due is greater than 0"
                          )}
                        </Typography>
                      )
                    }
                    disabled={
                      (roomHasPosOrders &&
                        ["All", "Pos"].includes(settlementType)) ||
                      Number(summaryData?.balance_due) <= 0
                    }
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={t("Date")}
                      value={bankTransferModeDate}
                      onChange={(newValue) => {
                        setBankTransferModeDate(newValue);
                      }}
                      name="upi_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Remarks")}
                    name="description"
                    value={bankTransferModeDesc}
                    onChange={(e) => setBankTransferModeDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="settleDuesSubmitBtns">
                <button
                  className="cancelBtn"
                  onClick={() => handleCloseBankTransferModePay()}
                >
                  {t("Cancel")}
                </button>
                {bankTransferModePayLoading === false ? (
                  <button
                    className="submit"
                    onClick={() => handleBankTransferPayment()}
                    disabled={
                      (bankTransferModeAmt > 0 ? false : true) ||
                      currentPaymentSelectionMode.length === 0
                    }
                  >
                    {t("Submit")}
                  </button>
                ) : (
                  <button className="submit">
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Modal>

        {/* settle dues --- record payment from other sources */}
        <Modal
          open={openOtherModePay}
          onClose={handleCloseOtherModePay}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="settleDuesModal">
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Enter amount")}
                  <div className="roomIdHeader">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("Room Id")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleRoomIdChange(e.target.value)}
                        style={{ marginLeft: "0rem" }}
                        label={t("Room Id")}
                        value={selectedRoomId}
                      >
                        {roomIdMapping?.map((resItem, index) => {
                          return (
                            <MenuItem
                              value={resItem?.roomId}
                              key={resItem?.resId}
                            >
                              {resItem?.roomId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  className="close"
                  onClick={() => handleCloseOtherModePay()}
                >
                  X
                </div>
              </div>
              <div className="amountWrapper">
                {roomHasPosOrders && (
                  <div className="fieldWrapper">
                    <MultiPaymentModeSelect
                      currentPaymentSelectionMode={currentPaymentSelectionMode}
                      setCurrentPaymentSelectionMode={
                        setCurrentPaymentSelectionMode
                      }
                      selectedLanguage={selectedLanguage}
                    />
                  </div>
                )}
                <div className="fieldWrapper">
                  <TextField
                    required
                    id="outlined-name"
                    label={t("Deposit amount")}
                    name="deposit_amount"
                    // value={otherModeAmt}
                    value={settlementTypeAmount}
                    // onChange={(e) =>
                    //   /^[0-9.]*$/.test(e.target.value)
                    //     ? setOtherModeAmt(e.target.value)
                    //     : setOtherModeAmt(otherModeAmt)
                    // }
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setSettlementTypeAmount(e.target.value)
                        : setSettlementTypeAmount(settlementTypeAmount)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    helperText={
                      Number(summaryData?.balance_due) <= 0 && (
                        <Typography sx={{ color: "red", fontSize: "10px" }}>
                          {t(
                            "Payment is already made. You can collect the payment once the balance due is greater than 0"
                          )}
                        </Typography>
                      )
                    }
                    disabled={
                      (roomHasPosOrders &&
                        ["All", "Pos"].includes(settlementType)) ||
                      Number(summaryData?.balance_due) <= 0
                    }
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={t("Service date")}
                      value={otherModeDate}
                      onChange={(newValue) => {
                        setOtherModeDate(newValue);
                      }}
                      name="deposit_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Description")}
                    name="other_mode_desc"
                    value={otherModeDesc}
                    onChange={(e) => setOtherModeDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="settleDuesSubmitBtns">
                  <button
                    className="cancelBtn"
                    onClick={() => handleCloseOtherModePay()}
                  >
                    {t("Cancel")}
                  </button>
                  {otherModePayLoading === false ? (
                    <button
                      className="submit"
                      onClick={() => handleOtherModePayment()}
                      disabled={
                        (otherModeAmt > 0 ? false : true) ||
                        currentPaymentSelectionMode.length === 0
                      }
                    >
                      {t("Submit")}
                    </button>
                  ) : (
                    <button className="submit">
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        {/* settle dues --- cash deposit modal */}
        <Modal
          open={openCashDeposit}
          onClose={handleCloseCashDeposit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="settleDuesModal">
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Enter deposit amount")}
                  <div className="roomIdHeader">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("Room Id")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleRoomIdChange(e.target.value)}
                        style={{ marginLeft: "0rem" }}
                        label={t("Room Id")}
                        value={selectedRoomId}
                      >
                        {roomIdMapping?.map((resItem, index) => {
                          return (
                            <MenuItem
                              value={resItem?.roomId}
                              key={resItem?.resId}
                            >
                              {resItem?.roomId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="close" onClick={() => handleCloseCashDeposit()}>
                  X
                </div>
              </div>
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    required
                    id="outlined-name"
                    label={t("Cash deposit amount*")}
                    name="deposit_amount"
                    value={depositAmt}
                    onChange={(e) =>
                      /^[0-9.]*$/.test(e.target.value)
                        ? setDepositAmt(e.target.value)
                        : setDepositAmt(depositAmt)
                    }
                    sx={{ width: "100%" }}
                    type={"text"}
                    helperText={
                      Number(summaryData?.balance_due) <= 0 && (
                        <Typography sx={{ color: "red", fontSize: "10px" }}>
                          {t(
                            "Payment is already made. You can collect the payment once the balance due is greater than 0"
                          )}
                        </Typography>
                      )
                    }
                    disabled={Number(summaryData?.balance_due) <= 0}
                  />
                </div>
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={t("Service date")}
                      value={depositDate}
                      onChange={(newValue) => {
                        setDepositDate(newValue);
                      }}
                      name="deposit_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Description*")}
                    name="folio_item_name"
                    value={depositName}
                    onChange={(e) => setDepositName(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="settleDuesSubmitBtns">
                  <button
                    className="cancelBtn"
                    onClick={() => handleCloseCashDeposit()}
                  >
                    {t("Cancel")}
                  </button>
                  {depositLoading === false ? (
                    <button
                      className="submit"
                      onClick={() => handleDeposit()}
                      disabled={depositAmt > 0 ? false : true}
                    >
                      {t("Submit")}
                    </button>
                  ) : (
                    <button className="submit">
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        {/* settle dues --- view/Manage  modal */}
        <Modal
          open={openViewManagePayments}
          onClose={handleCloseViewManagePayments}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEscapeKeyDown={true}
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {" "}
                  {t("View/Manage Payments")}
                </div>
                <div
                  className="close"
                  onClick={() => handleCloseViewManagePayments()}
                >
                  X
                </div>
              </div>
              <div className="viewManageToggleBtns d-flex mt-2">
                <button
                  className={`btn submit mr-2 ${
                    viewManageView === 0 ? "activeBtn" : "inactiveBtn"
                  }`}
                  onClick={() => {
                    setViewManageView(0);
                    setPaymentType("gateway");
                  }}
                >
                  {t("Payment Gateway")}
                </button>
                <button
                  className={`btn submit mr-2 ${
                    viewManageView === 1 ? "activeBtn" : "inactiveBtn"
                  }`}
                  onClick={() => {
                    setViewManageView(1);
                    setPaymentType("counter");
                  }}
                >
                  {t("Counter Payments")}
                </button>
                <button
                  className={`btn submit mr-2 ${
                    viewManageView === 2 ? "activeBtn" : "inactiveBtn"
                  }`}
                  onClick={() => {
                    setViewManageView(2);
                    setPaymentType("deposit");
                  }}
                >
                  {t("Cash Deposits")}
                </button>
                <button
                  className={`btn submit ${
                    viewManageView === 3 ? "activeBtn" : "inactiveBtn"
                  }`}
                  onClick={() => {
                    setViewManageView(3);
                    setPaymentType("cta");
                  }}
                >
                  {t("Direct billing")}
                </button>
                {hotelType === "ISHA" && (
                  <button
                    className={`btn submit ${
                      viewManageView === 4 ? "activeBtn" : "inactiveBtn"
                    }`}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      setViewManageView(4);
                    }}
                  >
                    {t("GPMS Refunds")}
                  </button>
                )}
              </div>
              {paymentLogData ? (
                <div className="amountWrapper">
                  {viewManageView === 0 && (
                    <PaymentGatewayLogTable
                      paymentGatewayLogData={paymentLogData?.pg_payments}
                      updateInvoice={() => updateInvoice()}
                      updateSummaryData={() => updateSummaryData()}
                      upDateViewManage={() => handlePaymentLogData()}
                      bookingId={bookingId}
                      hotelId={hotelId}
                      loggedUserEmail={loggedUserEmail}
                      setOpenManagePay={() => handleOpenViewManagePayments()}
                      pgName={pgName}
                      selectedLanguage={selectedLanguage}
                    />
                  )}
                  {viewManageView === 1 && (
                    <CounterPaymentsLogTable
                      counterPaymentsData={paymentLogData?.counter_payments}
                      updateInvoice={() => updateInvoice()}
                      updateSummaryData={() => updateSummaryData()}
                      upDateViewManage={() => handlePaymentLogData()}
                      bookingId={bookingId}
                      hotelId={hotelId}
                      loggedUserEmail={loggedUserEmail}
                      setOpenManagePay={() => handleOpenViewManagePayments()}
                      pgName={pgName}
                      selectedLanguage={selectedLanguage}
                    />
                  )}
                  {viewManageView === 2 && (
                    <CashDepositsTable
                      cashDepositsData={paymentLogData?.deposit_report}
                      updateInvoice={() => updateInvoice()}
                      updateSummaryData={() => updateSummaryData()}
                      upDateViewManage={() => handlePaymentLogData()}
                      bookingId={bookingId}
                      hotelId={hotelId}
                      loggedUserEmail={loggedUserEmail}
                      setOpenManagePay={() => handleOpenViewManagePayments()}
                      pgName={pgName}
                      selectedLanguage={selectedLanguage}
                    />
                  )}
                  {viewManageView === 3 && (
                    <BillToCTATable
                      cashDepositsData={paymentLogData?.bill_to_cta_report}
                      updateInvoice={() => updateInvoice()}
                      updateSummaryData={() => updateSummaryData()}
                      upDateViewManage={() => handlePaymentLogData()}
                      bookingId={bookingId}
                      hotelId={hotelId}
                      loggedUserEmail={loggedUserEmail}
                      pgName={pgName}
                      selectedLanguage={selectedLanguage}
                    />
                  )}
                  {viewManageView === 4 && (
                    <IshaGpms
                      updateInvoice={() => updateInvoice()}
                      updateSummaryData={() => updateSummaryData()}
                      bookingId={bookingId}
                      hotelId={hotelId}
                      loggedUserEmail={loggedUserEmail}
                      pgName={pgName}
                    />
                  )}
                </div>
              ) : (
                <MockLoaderTable />
              )}
            </div>
          </Box>
        </Modal>
        {/* settle dues --- paymentLink */}
        <Modal
          open={openSendPaymentLink}
          onClose={handleCloseSendPaymentLink}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Send Payment Link")}
                </div>
                <div
                  className="close"
                  onClick={() => handleCloseSendPaymentLink()}
                >
                  X
                </div>
              </div>
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Email")}
                    name="email"
                    helperText={t(
                      "By default customer's email has been populated. Override if necessary."
                    )}
                    value={paymentLinkEmail}
                    onChange={(e) => setPaymentLinkEmail(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Mobile number")}
                    name="mobile"
                    helperText={t(
                      "By default customer's mobile has been populated. Override if necessary."
                    )}
                    value={paymentLinkMobile}
                    onChange={(e) => setPaymentLinkMobile(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Amount")}
                    helperText={
                      <>
                        <Typography sx={{ fontSize: "8px" }}>
                          {t(
                            "By default total due amount has been populated.Override if necessary."
                          )}
                        </Typography>
                        {Number(summaryData?.balance_due) <= 0 && (
                          <Typography sx={{ color: "red", fontSize: "8px" }}>
                            {t(
                              "Payment is already made. You can collect the payment once the balance due is greater than 0"
                            )}
                          </Typography>
                        )}
                      </>
                    }
                    name="service_amount_desc"
                    value={paymentLinkAmt}
                    onChange={(e) => setPaymentLinkAmt(e.target.value)}
                    sx={{ width: "100%" }}
                    disabled={Number(summaryData?.balance_due) <= 0}
                  />
                </div>
              </div>
              <div className="settleDuesSubmitBtns">
                <button className="cancelBtn">{t("Cancel")}</button>
                {paymentLinkLoading === false && hotelType !== "ISHA" ? (
                  <button
                    className="submit w-auto"
                    onClick={() => handlePaymentLink()}
                    disabled={
                      Number(summaryData?.balance_due) <= 0 ||
                      !paymentLinkEmail ||
                      !paymentLinkMobile ||
                      !paymentLinkAmt
                    }
                  >
                    {t("Send Payment Link")}
                  </button>
                ) : (
                  <button className="submit">
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Modal>
        {/* settle dues --- add Card */}
        <Modal
          open={openAddCard}
          onClose={handleCloseAddCard}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">{t("Add Card")}</div>
                <div className="close" onClick={() => handleCloseAddCard()}>
                  X
                </div>
              </div>
              <AddCard
                eventProperties={amplitudeEventProperties}
                hotelId={hotelId}
                custEmail={folioSummary?.invoice?.customer_email}
                updateInvoice={() => updateInvoice()}
                closeAddCard={() => handleCloseAddCard()}
                loggedUserEmail={loggedUserEmail}
                updateHotelPgData={() => updateHotelPgData()}
                updateSummaryData={() => updateSummaryData()}
                bookingId={bookingId}
                selectedLanguage={selectedLanguage}
              />
            </div>
          </Box>
        </Modal>
        {/* settle dues ---  perform EMV transaction  */}
        <Modal
          open={openPerformEMVTransaction}
          onClose={handleClosePerformEMVTransaction}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Perform EMV Transaction")}
                </div>
                <div
                  className="close"
                  onClick={() => handleClosePerformEMVTransaction()}
                >
                  X
                </div>
              </div>

              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Enter Amount")}
                    name="emv_amount"
                    value={performEMVTransactionAmt}
                    onChange={(e) =>
                      setPerformEMVTransactionAmt(e.target.value)
                    }
                    sx={{ width: "100%" }}
                  />
                </div>

                {customPgData.hasOwnProperty("terminals") && (
                  <div className="fieldWrapper mt-4">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-autowidth-label">
                        {t("Terminal Id(s)")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={emvTerminalId}
                        label={t("Terminal Id(s)")}
                        onChange={(e) => setEmvTerminalId(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {customPgData?.terminals?.map((item, idx) => (
                          <MenuItem value={item.id} key={idx}>
                            {item.terminal_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div className="fieldWrapper mt-1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={performEMVTransactionSaveCard}
                        checked={performEMVTransactionSaveCard}
                        onChange={(e) =>
                          setPerformEMVTransactionSaveCard(e.target.checked)
                        }
                      />
                    }
                    label={t("Save card for booking")}
                  />
                </div>
                <div className="settleDuesSubmitBtns">
                  <button className="cancelBtn">{t("Cancel")}</button>
                  <div className="d-flex w-auto">
                    {performEMVTransactionLoading1 === false ? (
                      <button
                        className="submit w-auto mr-2"
                        onClick={() => handlePerformEMVTransactionCharge()}
                      >
                        {t("Charge")}
                      </button>
                    ) : (
                      <button className="submit mr-2">
                        <CircularProgress size="15px" />
                      </button>
                    )}

                    {/* test */}
                    {enableRefundCard &&
                      JSON.parse(
                        data?.accessControl?.user_feature_map
                          ?.PaymentGatewayRefund
                      ).read === "YES" &&
                      JSON.parse(
                        data?.accessControl?.user_feature_map?.HideDirectRefund
                      ).read === "YES" && (
                        <>
                          {performEMVTransactionLoading3 === false ? (
                            <button
                              className="submit w-auto mr-2"
                              onClick={() =>
                                handlePerformEMVTransactionRefund()
                              }
                            >
                              {t("Refund")}
                            </button>
                          ) : (
                            <button className="submit mr-2">
                              <CircularProgress size="15px" />
                            </button>
                          )}
                        </>
                      )}

                    <>
                      {performEMVTransactionLoading2 === false ? (
                        <button
                          className="submit w-auto"
                          onClick={() => handlePerformEMVTransactionHold()}
                        >
                          {t("Authorize Hold")}
                        </button>
                      ) : (
                        <button className="submit">
                          <CircularProgress size="15px" />
                        </button>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        {/* settle dues ---  perform Razorpay POS transaction  */}
        <Modal
          open={openPerformRazorpayPOSTransaction}
          onClose={() => setOpenPerformRazorpayPOSTransaction(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Perform Razorpay POS Transaction")}
                </div>
                <div
                  className="close"
                  onClick={() => setOpenPerformRazorpayPOSTransaction(false)}
                >
                  X
                </div>
              </div>

              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Enter Amount")}
                    value={posPaymentAmount}
                    onChange={(e) => setPosPaymentAmount(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>

                <div className="fieldWrapper mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      Payment mode
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Payment mode"
                      value={posPaymentMode}
                      onChange={(e) => setPosPaymentMode(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value={"CARD"}>CARD</MenuItem>
                      <MenuItem value={"UPI"}>UPI</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/* <div className="fieldWrapper mt-1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={performEMVTransactionSaveCard}
                        checked={performEMVTransactionSaveCard}
                        onChange={(e) =>
                          setPerformEMVTransactionSaveCard(e.target.checked)
                        }
                      />
                    }
                    label={t("Save card for booking")}
                  />
                </div> */}
                <div className="settleDuesSubmitBtns">
                  <div className="d-flex w-auto">
                    <button
                      className="submit w-auto mr-2"
                      onClick={initiatePayment}
                      disabled={initiatePaymetLoader || p2pRequestId}
                    >
                      {t("Initiate payment")}{" "}
                      {initiatePaymetLoader && <CircularProgress size="15px" />}
                    </button>

                    <button
                      className="submit w-auto mr-2"
                      onClick={getPaymentStatus}
                      disabled={
                        paymentStatusLoader ||
                        abortPaymentLoader ||
                        !p2pRequestId
                      }
                    >
                      {t("Payment status")}{" "}
                      {paymentStatusLoader && <CircularProgress size="15px" />}
                    </button>

                    <button
                      className="submit w-auto"
                      onClick={cancelPayment}
                      disabled={
                        paymentStatusLoader ||
                        abortPaymentLoader ||
                        !p2pRequestId
                      }
                    >
                      {t("Abort payment")}{" "}
                      {abortPaymentLoader && <CircularProgress size="15px" />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        {/* settle dues ---  perform Online payment  */}
        <Modal
          open={performOnlinePaymentModal}
          onClose={closePerformOnlinePaymentModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Perform Online Payment")}
                </div>
                <div
                  className="close"
                  onClick={() => closePerformOnlinePaymentModal()}
                >
                  X
                </div>
              </div>

              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label={t("Enter Amount")}
                    name="online_payment_amount"
                    value={onlinePaymentAmount}
                    onChange={(e) => setOnlinePaymentAmount(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="settleDuesSubmitBtns">
                  <button
                    className="cancelBtn"
                    onClick={() => closePerformOnlinePaymentModal()}
                  >
                    {t("Cancel")}
                  </button>
                  <div className="d-flex w-auto">
                    {onlinePaymentLoader === false ? (
                      <button
                        className="submit w-auto mr-2"
                        onClick={() => handleCreateOnlinePaymentIntent()}
                      >
                        {t("Charge")}
                      </button>
                    ) : (
                      <button className="submit mr-2">
                        <CircularProgress size="15px" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        {/* Online payment --- stripe payment intent */}
        <Modal
          open={paymentIntentModal}
          onClose={closePaymentIntentModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">{t("Perform Payment")}</div>
                <div
                  className="close"
                  onClick={() => closePaymentIntentModal()}
                >
                  X
                </div>
              </div>

              <div className="amountWrapper">
                {/* Payment intent logic*/}
                {clientSecretKey !== "" && (
                  <Elements
                    stripe={
                      stripeAccount !== ""
                        ? loadStripe(`${stripePublicKey}`, {
                            stripeAccount: stripeAccount,
                          })
                        : loadStripe(`${stripePublicKey}`)
                    }
                    options={{
                      clientSecret: clientSecretKey,
                      appearance: {
                        theme: "stripe",
                        variables: {
                          colorPrimary: "#1ab394",
                          colorBackground: "#ffffff",
                          colorText: "#30313d",
                          colorDanger: "#f44336",
                          // See all possible variables below
                        },
                      },
                    }}
                  >
                    <StripeCheckoutForm
                      clientSecret={clientSecretKey}
                      paymentIntentId={paymentIntentId}
                      performPaymentButton={performPayment}
                      setPerformPaymentButton={setPerformPayment}
                      bookingId={bookingId}
                      successPayment={handleStripeSuccessPayment}
                      setButtonLoader={setOnlinePaymentLoader}
                      selectedLanguage={selectedLanguage}
                    />
                  </Elements>
                )}
              </div>
              <div className="settleDuesSubmitBtns">
                <div className="d-flex w-100">
                  {onlinePaymentLoader === false ? (
                    <button
                      className="submit w-100 mr-2"
                      onClick={() => setPerformPayment(true)}
                    >
                      {t("Pay now")}
                    </button>
                  ) : (
                    <button className="submit mr-2 w-100">
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        {/* settle dues ---  Added cards transaction */}
        <Modal
          open={openAddedCard}
          onClose={handleCloseAddedCard}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              maxWidth: "500px",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Perform action on")} **{openedAddedCardData?.card_last4}
                </div>
                <div className="close" onClick={() => handleCloseAddedCard()}>
                  X
                </div>
              </div>
              <ChargeAuthCardSaved
                hotelId={hotelId}
                bookingId={bookingId}
                openedAddedCardData={openedAddedCardData}
                invoice={folioSummary?.invoice}
                updateInvoice={() => updateInvoice()}
                updateSummaryDataFromCache={() => updateSummaryDataFromCache()}
                closeAddedCard={() => handleCloseAddedCard()}
                loggedUserEmail={loggedUserEmail}
                userType={userType}
                balanceDue={summaryData?.balance_due}
                isAdmin={isAdmin}
                paymentViewAccess={paymentViewAccess}
                enableRefund={enableRefundCard}
                selectedLanguage={selectedLanguage}
              />
            </div>
          </Box>
        </Modal>
        {/* settle dues ---  ota saved cards transaction */}
        <Modal
          open={openOTAOTAAddedCard}
          onClose={handleCloseOTAAddedCard}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              maxWidth: "450px",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Enter amount to charge")}
                </div>
                <div
                  className="close"
                  onClick={() => handleCloseOTAAddedCard()}
                >
                  X
                </div>
              </div>
              <ChargeAuthCardOTA
                hotelId={hotelId}
                bookingId={bookingId}
                OTACardData={otaBookingInfoData}
                updateInvoice={() => updateInvoice()}
                updateSummaryData={() => updateSummaryData()}
                updateSummaryDataFromCache={() => updateSummaryDataFromCache()}
                closeOTAAddedCard={() => handleCloseOTAAddedCard()}
                loggedUserEmail={loggedUserEmail}
                balanceDue={summaryData?.balance_due}
                isAdmin={isAdmin}
                selectedLanguage={selectedLanguage}
              />
            </div>
          </Box>
        </Modal>
        {/* settle dues --- direct cta billing */}
        <Modal
          open={openCta}
          onClose={handleCloseCta}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              maxWidth: "450px",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {t("Direct billing to")} -{" "}
                  {folioSummary?.invoice.booking_source_displayname}
                </div>
                <div className="close" onClick={() => handleCloseCta()}>
                  X
                </div>
              </div>
              <div className="amountWrapper">
                <div className="fieldWrapper">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={t("Service date")}
                      value={settleCtaDate}
                      onChange={(newValue) => {
                        setSettleCtaDate(newValue);
                      }}
                      name="settle_through_cta"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "100%" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className="fieldWrapper">
                  {ctaAmt > 0 ? (
                    <TextField
                      id="outlined-name"
                      label={t("Amount")}
                      name="amount"
                      value={ctaAmt}
                      onChange={(e) => setCtaAmt(e.target.value)}
                      sx={{ width: "100%" }}
                      type={"number"}
                      InputProps={{
                        inputProps: { min: 1, max: summaryData?.balance_due },
                      }}
                      error={
                        Number(ctaAmt) > Number(summaryData?.balance_due)
                          ? true
                          : false
                      }
                      helperText={
                        Number(ctaAmt) > Number(summaryData?.balance_due) && (
                          <Typography sx={{ color: "red", fontSize: "10px" }}>
                            {t("Please enter a valid amount.")}{" "}
                          </Typography>
                        )
                      }
                    />
                  ) : (
                    <TextField
                      id="outlined-name"
                      label={t("Amount")}
                      name="amount"
                      value={ctaAmt}
                      error
                      helperText={t(
                        "Please enter the  value greater than or equal 1"
                      )}
                      onChange={(e) => setCtaAmt(e.target.value)}
                      sx={{ width: "100%" }}
                      type={"number"}
                    />
                  )}
                </div>

                <div className="fieldWrapper">
                  <TextField
                    id="outlined-name"
                    label="Optional description"
                    name="optional_desc"
                    value={ctaDesc}
                    onChange={(e) => setCtaDesc(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="settleDuesSubmitBtns">
                  <button
                    className="cancelBtn"
                    onClick={() => handleCloseCta()}
                  >
                    {t("Cancel")}
                  </button>
                  {ctaLoader === false ? (
                    <button
                      className="submit"
                      onClick={() => handleDirectCtaBilling()}
                      disabled={
                        Number(ctaAmt) > Number(summaryData?.balance_due)
                          ? true
                          : Number(ctaAmt) === 0
                          ? true
                          : false
                      }
                    >
                      {t("Submit")}
                    </button>
                  ) : (
                    <button className="submit">
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        {/* Defining modal for handling checkout ---Whether to void charges or not */}
        <Modal
          open={openCheckoutModal}
          onClose={handleCloseCheckoutModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              maxWidth: "450px",
            }}
          >
            <div className="settleDuesWrapper">
              <div
                className="settleDuesHeader"
                style={{ borderBottom: "1px solid #DDDDDD" }}
              >
                <div className="settleDuesHeading">
                  {t("Checkout booking for")} {bookingId}
                </div>
                <div
                  className="close"
                  onClick={() => handleCloseCheckoutModal()}
                >
                  X
                </div>
              </div>
              <Stack>
                {Number(summaryData.balance_due) === 0 ? (
                  <Stack className="mt-4">
                    {t(
                      "Are you sure you want to check-out ? Please click on submit to proceed."
                    )}
                  </Stack>
                ) : (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      {t("Please select")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={voidTypeOption}
                      label={t("Void charge options")}
                      onChange={handleVoidTypeOption}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {voidTypeOptions.map((item) => (
                        <MenuItem value={item.name}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <div className="settleDuesSubmitBtns">
                  <button
                    className="cancelBtn"
                    onClick={() => handleCloseCheckoutModal()}
                  >
                    {t("Cancel")}
                  </button>
                  {checkoutLoading === false ? (
                    <button className="submit" onClick={() => handleCheckout()}>
                      {t("Submit")}
                    </button>
                  ) : (
                    <button className="submit">
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </Stack>
            </div>
          </Box>
        </Modal>
        {/* Early checkout modal */}
        <Modal
          open={showEarlyCheckout}
          onClose={closeEarlyCheckoutModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              maxWidth: "600px",
            }}
          >
            <div className="settleDuesWrapper">
              <div
                className="settleDuesHeader"
                style={{ borderBottom: "1px solid #DDDDDD" }}
              >
                <div className="settleDuesHeading">
                  {t("Early checkout alert")}
                </div>
                <div
                  className="close"
                  onClick={() => closeEarlyCheckoutModal()}
                >
                  X
                </div>
              </div>
              <Stack>
                <Stack className="mt-4 mb-5">
                  <b>{t("You are requesting an early check-out.")}</b>
                  {t(
                    " The system will automatically update the reservation's check-out date to release the inventory."
                  )}
                </Stack>
                <Grid container>
                  <Grid item xs={5} textAlign={"start"}>
                    <b>{t("Booking ID")}</b>
                  </Grid>
                  <Grid item xs={1} textAlign={"start"}>
                    :
                  </Grid>
                  <Grid item xs={6} textAlign={"start"}>
                    <Typography>{bookingId}</Typography>
                  </Grid>
                  <Grid item xs={5} textAlign={"start"}>
                    <b>{t("Guest name")}</b>
                  </Grid>
                  <Grid item xs={1} textAlign={"start"}>
                    :
                  </Grid>
                  <Grid item xs={6} textAlign={"start"}>
                    <Typography>
                      {folioSummary?.invoice?.customer_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} textAlign={"start"}>
                    <b>{t("Check-in Date")}</b>
                  </Grid>
                  <Grid item xs={1} textAlign={"start"}>
                    :
                  </Grid>
                  <Grid item xs={6} textAlign={"start"}>
                    <Typography>
                      {moment(
                        folioSummary?.invoice?.actual_checkin.replace("T", " "),
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("MMM Do YYYY, h A")}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} textAlign={"start"}>
                    <b>{t("Original Check-out Date")}</b>
                  </Grid>
                  <Grid item xs={1} textAlign={"start"}>
                    :
                  </Grid>
                  <Grid item xs={6} textAlign={"start"}>
                    <Typography>
                      {moment(
                        folioSummary?.invoice?.actual_checkout.replace(
                          "T",
                          " "
                        ),
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("MMM Do YYYY, h A")}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={5} textAlign={"start"}>
                    <b>{t("New Check-out Date")}</b>
                  </Grid>
                  <Grid item xs={1} textAlign={"start"}>
                    :
                  </Grid>
                  <Grid item xs={6} textAlign={"start"}>
                    <Typography>
                      {moment(newCheckoutDate).format("MMM Do YYYY, h A")}
                    </Typography>
                  </Grid> */}
                </Grid>
                <Grid container className="mt-5">
                  {Number(summaryData.balance_due) !== 0 && (
                    <>
                      <Grid item xs={12}>
                        <b> {t("Please select a void type")}</b>
                      </Grid>
                      <Grid item xs={12} className="mt-2">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-autowidth-label">
                            {t("Void type")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={voidTypeOption}
                            label={t("Void charge options")}
                            onChange={handleVoidTypeOption}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {voidTypeOptions.map((item) => (
                              <MenuItem value={item.name}>
                                {item.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
                <div className="settleDuesSubmitBtns">
                  <button
                    className="cancelBtn"
                    onClick={() => closeEarlyCheckoutModal()}
                  >
                    {t("Cancel")}
                  </button>
                  {earlyCheckoutLoader === false ? (
                    <button
                      className="submit"
                      style={{ width: "25%" }}
                      onClick={() => handleEarlyCheckout()}
                    >
                      {t("Checkout booking")}
                    </button>
                  ) : (
                    <button className="submit">
                      <CircularProgress size="15px" />
                    </button>
                  )}
                </div>
              </Stack>
            </div>
          </Box>
        </Modal>
        {/* Confirmation modal to remove the promocode */}
        <Modal
          open={openConfirmRemovePromo}
          onClose={handleCloseConfirmRemovePromo}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              maxWidth: "450px",
            }}
          >
            <div className="settleDuesWrapper">
              <div
                className="settleDuesHeader"
                style={{ borderBottom: "1px solid #DDDDDD" }}
              >
                <div className="settleDuesHeading">
                  {t("Confirm remove promocode")}
                </div>
                <div
                  className="close"
                  onClick={() => handleCloseConfirmRemovePromo()}
                >
                  X
                </div>
              </div>
              <Stack className="position-relative w-100 mt-2">
                <Typography>
                  {t("Are you sure you want to remove")}{" "}
                  <b>{folioSummary?.invoice?.applied_promocode}</b>
                  {t("for this booking? Click on the")}{" "}
                  <strong>{t("Confirm")}</strong> {t("button to proceed.")}
                </Typography>
              </Stack>
              <div className="settleDuesSubmitBtns">
                <button
                  className="cancelBtn"
                  onClick={() => handleCloseConfirmRemovePromo()}
                >
                  {t("Cancel")}
                </button>
                <Button
                  variant="custom-button"
                  onClick={() => !removePromoLoading && handleRemovePromocode()}
                >
                  {removePromoLoading ? (
                    <CircularProgress size="15px" />
                  ) : (
                    `${t("Confirm")}`
                  )}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      {summaryData?.deposit_amount > 0.0 && (
        <div className="cashDeposit">
          <div className="cashDepositHeader">{t("Cash deposit")}</div>
          {hotelCurrency === hotelConvertedCurrency && (
            <div className="cashDepositVal">
              {currency} {summaryData?.deposit_amount.toFixed(2)}
            </div>
          )}
          {hotelCurrency !== hotelConvertedCurrency && (
            <div className="cashDepositVal">
              {hotelConvertedCurrencySymbol}{" "}
              {(summaryData?.deposit_amount * convertedAmount).toFixed(2)}
            </div>
          )}
        </div>
      )}
      {summaryData?.auths && summaryData?.auths.length > 0 && (
        <div className="cashDeposit">
          <div className="cashDepositHeader">{t("Authorized payments")}</div>
          <Stack spacing={1}>
            {summaryData?.auths?.map((item, index) => (
              <div
                key={index}
                className="cashDepositVal"
                style={{
                  position: "relative",
                  width: "100%",
                  display: "grid",
                  flexDirection: "row",
                  gridTemplateColumns: "60% 40%",
                }}
              >
                <Typography
                  className="position-relative w-100 d-flex flex flex-wrap"
                  style={{ wordBreak: "break-word" }}
                >
                  {item?.pm_payment_type} {item?.pm_payment_issuer}
                </Typography>
                <Typography
                  className="position-relative w-100 d-flex flex flex-wrap justify-content-end"
                  style={{ wordBreak: "break-word" }}
                >
                  {currency} {item?.pm_amount}
                </Typography>
              </div>
            ))}
          </Stack>
        </div>
      )}
      <Modal
        open={openAddressAlert}
        onClose={handleCloseAddressAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            sx={{
              position: "relative",
              width: "100%",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Typography>
              <strong>{t("Alert")}</strong>
            </Typography>
            <Typography
              sx={{ fontSize: "15px", fontWeight: 600, cursor: "pointer" }}
              onClick={() => handleCloseAddressAlert()}
            >
              X
            </Typography>
          </Stack>
          <Divider />
          <Typography sx={{ padding: "10px", fontSize: "16px", color: "red" }}>
            {t(
              "Address, Phone number and Email is mandatory to proceed. Kindly fill in all the necessary details in order to settle the dues."
            )}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(InvoiceSummary);
